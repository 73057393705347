@mixin style-th {
	border-color: inherit;
    background-color: $table-header;
    color: $font-light;
    vertical-align: middle;
    padding: 10px 15px;
}

@mixin style-td {
    padding: 10px 15px;
	border-color: inherit;
}

@mixin mobile-dropdown-icon {
	background-color: $corporate-black;
	border: .15em solid rgb(0,0,0,.5);
}

.text {
    table {
        @extend .table;
        @extend .table-borderless;
    }
    &.defaultLightTableStyle {
        table {
            color: $corporate-white;
            border-color: $corporate-white;
            @extend .text-light;
        }
        &.borderTableStyle {
            table {
                @extend .table-bordered;
                border-color: $corporate-white;
            }
        }
    }
    &.lightTableStyle {
        table {
            @extend .table-light;
            @extend .table-striped;
        }
        &.headerTableStyle {
            table tr:first-of-type {
                color: $corporate-black;
                background-color: $neutral-20;
                --bs-table-bg: $neutral-20;
                --bs-table-striped-bg: $neutral-20;
                --bs-table-active-bg: $neutral-20;
                --bs-table-hover-bg: $neutral-20;
            }
        }
    }
    &.darkTableStyle {
        table {
            @extend .table-dark;
            @extend .table-striped;
            @extend .text-light;
        }
        &.headerTableStyle {
            table tr:first-of-type {
                color: $corporate-white;
                background-color: $neutral-90;
                --bs-table-bg: $neutral-90;
                --bs-table-striped-bg: $neutral-90;
                --bs-table-active-bg: $neutral-90;
                --bs-table-hover-bg: $neutral-90;
            }
        }
    }
    &.borderTableStyle {
        table {
            @extend .table-bordered;
            border-color: $corporate-black;
        }
    }
    &.condensedTableStyle {
        table {
            @extend .table-sm;
        }
    }
    &.break-word {
        table {
            word-break: break-word;
        }
    }
}

.amd-bs5 {
    //table styles
    table.dataTable {
        width: 100% !important;
        border-color: $table-border-color;
		&.table-striped {
			>tbody {
				>tr {
					&.odd {
						--bs-table-accent-bg: rgb(225,225,226);
					}
				}
			}
		}
		&.table-bordered {
			border-left-width: 0px;
		}
		
		&.dtr-inline.collapsed>tbody>tr {
			td.dtr-control:before {
				@include mobile-dropdown-icon;
				font-family: var(--fa-style-family-sharp);
				content: "\f078";
				@include media-breakpoint-down(md) {
					border: .3em solid rgba(0,0,0,.5)
				}
			}
			&.selected, &.dt-hasChild {
				td.dtr-control:before {
					@include mobile-dropdown-icon;
					content: "\f077";
					@include media-breakpoint-down(md) {
						border: .3em solid rgba(0,0,0,.5)
					}
				}
			}
		}
		
		tbody {
				>tr {
					&.selected, >.selected {
						>*{
							background-color: $table-selected-color !important;
							color: $font-light;
						}
					}
					
				}
			}
			
        thead {
            tr {
				white-space: nowrap;
                th {
                	@include style-th;
					padding-right: 18px;
					vertical-align: text-top;
					&.sorting {
						&:before, &:after {
							color: $neutral-40;
							opacity: .6;
							padding: map-get($spacers, 10);
							right: 0;
						}
						&:before {
							top: map-get($spacers, 30);
						}
						&:after {
							top: 1.125rem;
						}
						&.sorting_asc:before, &.sorting_desc:after {
							opacity: 1;
						}
					}
					.form-select {
						margin-top: map-get($spacers, 40);
					}
                }
            }
        }
        tr {
        	th {
            	@include style-th;
        	}
        }
        tbody {
            tr {
                th {
                	@include style-th;
                }
                td {
                    .style-td
                    ul {
                        margin-bottom: 0;
                        padding-left: 15px;
                    }
                }
            }
        }
        tr {
        	td {
        		@include style-td;
        	}
        }
    }

	.dataTables_wrapper{
		.dt-buttons {
			button.buttons-html5 {
				margin-top: 0;
				color: $font-light;
				background-color: $font-dark;
				border: 1px solid $font-dark;
			    padding: 0.375rem 0.75rem;
				text-transform: unset;
			    @include box-shadow();
			}
		}
		.dataTables_paginate {
			ul {
			    &.pagination {
					flex-wrap: wrap;
					.page-item {
						.page-link {
							border: 1px solid $border-color;
							background: transparent;
							@include border-radius(0);
							@include box-transition();
							text-decoration: none;
							text-transform: uppercase;
							text-align: center;
							color: $font-dark;
							&:hover, &:focus {
								color: $font-light;
								background-color: $border-color;
								border-color: $border-color;
								@include box-shadow();
							}
						}
						&.disabled {
							.page-link {
								background: $gray;
								opacity: 0.4; 
								&:hover, &:focus {
									@include box-shadow();
								}
							}
						}
						&.active {
							.page-link {
								background: $font-dark;
								color: $font-light;
								&:hover, &:focus {
									@include box-shadow();
								}
							}
						}
					}
			    }
			}
		}
		@include media-breakpoint-down(md) {
			.dataTables_length {
				text-align: left;
				label {
					padding-bottom: 0.5rem;
				}
			}
			.dataTables_filter {
				text-align: left;
			}
		}
		.dt-button-row {
			.dt-filter-container {
				display: flex;
				justify-content: flex-end;
				.dt-clear-button {
					margin-left: map-get($spacers, 35);
				}
			}
		}
		.dl-content-nowrap {
			td {
				white-space: nowrap;
				@include media-breakpoint-down(md) {
					white-space: normal;
				}
				&.child {
					white-space: normal;
					dt, dd {
						margin-bottom: map-get($spacers, 35);
					}
				}
			}
		}
		.dtr-inline.collapsed {
			tbody {
				tr {
					td {
						&.dtr-control {
							padding-left: 2.5rem;
						}
					}
				}
			}
		}
	}
	.dataTables, .product-specs-table {
		&.dataTable-compact {
			@include medium;
			thead {
				tr {
					th {
						padding: map-get($spacers, 35) map-get($spacers, 45) map-get($spacers, 35) map-get($spacers, 30);
					}
				}
			}
			tr {
				td {
					padding: map-get($spacers, 30);
				}
			}
		}
		.dataTables_wrapper {
			> table.dataTable {
				margin-top: map-get($spacers, 40) !important;
			}
			.dt-buttons {
				button.dt-button {
					padding: 0.325rem map-get($spacers, 35);
					border-radius: 8px;
					border: 1px solid $gray-400;
					background: $corporate-white;
				}
				.buttons-html5 {
					color: $corporate-black;
				}
			}
		}
		.dataTables_scrollHead {
			table.dataTable {
				margin-top: map-get($spacers, 40) !important;
			}
		}
		.dtfc-right-top-blocker {
			display: none !important;
		}
		&.darkDataTable {
			.dataTables_scrollHead th:first-child{
				border-left-color: rgb(55, 59, 62) !important;
			}
			.dataTables_scrollBody {
				border-left-width: 0px !important;
			}
			table {
				@extend .table-dark;
				@extend .table-striped;
				@extend .text-light;
				tbody {
					.dtfc-fixed-left, .dtfc-fixed-right {
						background-color: var(--bs-table-bg);
						border-left-color: inherit;
					}
				}
				&.table-bordered td:first-child {
					border-left-width: 1px;
					border-left-color: rgb(55, 59, 62);
				}
			}
			.dt-button-row, .dataTables_info {
				color: $corporate-white;
			}
			.dataTables_wrapper {
				.dataTables_paginate {
					ul.pagination {
						.page-item {
							.page-link {
								background: $neutral-60;
								color: $corporate-white;
							}
							&.active {
								.page-link {
									background: $neutral-40;
								}
							}
							&.disabled {
								.page-link {
									background: $neutral-60;
									opacity: 0.4;
								}
							}
						}
					}
				}
			}
		}
	}

}
