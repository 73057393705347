.amd-bs5 {
  .relatedContent,
  .relatedContentCoveo {
    --text-color: #{$corporate-black};
    --light-text-color: #{$neutral-60};
    --card-blur: none;

    @include teaser-card-image();

    .card-body {
      @include teaser-card-body();
      @include teaser-card-text();

      .card-title {
        @include teaser-title();
      }
    }

    &.text-light {
      --text-color: #{$corporate-white};
      --light-text-color: #{$neutral-40};
      --card-blur: blur(5px);

		.related-content {
	      .card.related-content-card {
	        background-color: rgba($neutral-90, 0.8);
	      }
      }
    }

    .related-content {
		 /**
		  * Trick to get Full Width Containers in Limited Width Parents. 
		  * The idea here is: push the container to the exact middle of the browser window with left: 50%;, 
		  * then pull it back to the left edge with negative -50vw margin.
		  */
		@include media-breakpoint-down(xl) {
			width: 100vw;
			position: relative;
			left: 50%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
		}
      .card-body {
        .card-title {
          a {
            color: var(--text-color);
            font-weight: 600;
          }
        }

        .card-date {
          color: var(--light-text-color);
          font-size: $font-size-sm;
        }

        .card-text {
          flex-grow: 1;
        }
      }

      .glide__slide {
        display: flex;
        height: auto;
      }

      .card.related-content-card {
        color: var(--text-color);
        background-color: $corporate-white;
        backdrop-filter: var(--card-blur);
        @include teaser-card;

        &:hover .card-title > a {
          text-decoration: underline;
        }

        .card-full-link {
          position: absolute;
          left: 0;
          top: 0;
          inset: 0;
        }

        img.related-content-image {
          padding-bottom: 0;
          transition: transform 0.3s, visibility 0.3s ease-in-out;
          transform-origin: center;
          width: 100%;
        }

        .card-image-top {
          overflow: hidden;
        }

        &:hover,
        &:focus-within {
          box-shadow: $lifted;

          img {
            transform: scale(1.1);
            user-drag: none;
            -webkit-user-drag: none;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
          }
        }
      }

      .glide-bullets {
        text-align: center;
        @include generate-spacing(padding, 45, 'b');

        button {
          width: 10px;
          height: 10px;
          border-radius: 100%;
          border: 0;
          background-color: $gray-500;
          padding: 0;
          @include generate-spacing(margin, 25, 's');
          @include generate-spacing(margin, 25, 'e');

          &.glide__bullet--active {
            background-color: $gray-600;
          }
        }
      }

      .glide__arrow {
        --glide-arrows-top: 45%;
        @include carousel-arrows();
        @include carousel-arrows-outside-position();
      }
    }
  }
}
