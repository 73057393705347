.amd-bs5 {
	.yt-embed {
		@include media-breakpoint-down(xl) {
			.yt-ot-overlay {
				justify-content: center !important;
				position: relative !important;
			}
			.yt-video:has(.yt-ot-content) {
				height: fit-content;
				&:before, &:after {
					display: none;
				}
			}
		}
		.yt-ot-overlay {
			width: 100%;
			height: 100%;
			backdrop-filter: blur(var(--background-blur)) brightness(60%);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			position: absolute;
		}
		.yt-ot-settings {
			border-radius: $border-radius-lg;
			border-color: black;
			padding: 10px 25px 10px 25px;
			color: white;
			background-color: black;
			@include media-breakpoint-down(sm) {
				padding: 5px 16px;
			}
		}
		.yt-ot-settings-label {
			font-weight: 500;
		}
		.yt-button-row {
			display: flex;
			flex-wrap: nowrap;
			align-items: baseline;
			gap: 1rem;
			justify-content: space-between;
			border-radius: 10px;
			color: $corporate-black;
		}
		.yt-ot-content {
			color: $corporate-black;
			margin: clamp(0.25rem, -0.802rem + 2.927vw, 2.125rem);
			padding: clamp(0.25rem, -0.171rem + 1.171vw, 1rem);
			border-radius: $border-radius-lg;
			background-color: white;
			box-shadow: 0 0 20px 5px #000;
			flex-basis: fit-content;
			h6 {
				margin-bottom: clamp(0rem, -0.561rem + 1.561vw, 1rem);
				font-size: clamp(0.825rem, 0.654rem + 0.476vw, 1.13rem);
			}
			p {
				font-size: clamp(0.825rem, 0.727rem + 0.273vw, 1rem);
				margin-bottom: clamp(0.25rem, -0.521rem + 2.146vw, 1.625rem);
				line-height: clamp(1.25rem, 1.04rem + 0.585vw, 1.625rem);
			}
			@include media-breakpoint-down(sm) {
				box-shadow: none;
			}
		}
		.yt-ot-direct-link {
			align-self: flex-end;
			@include media-breakpoint-down(md) {
				align-self: center;
			}
			border-radius: 3rem !important;
			background-color: white;
			padding: .625rem 1.5rem .625rem 1.5rem !important;
			@include generate-spacing(margin, 50, 't');
			box-shadow: 0 0 20px 5px #000;
			color: black;
			font-weight: 500;
			margin-right: clamp(0.25rem, -0.802rem + 2.927vw, 2.125rem);
			margin-left: clamp(0.25rem, -0.802rem + 2.927vw, 2.125rem);
			font-size: clamp(0.825rem, 0.727rem + 0.273vw, 1rem);
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		/* consent msg */

		.fa-youtube {
			color: #ff0000;
			font-size: clamp(1rem, 0.439rem + 1.561vw, 2rem);
			padding-right: .25rem;

		}
		.yt-ot-consent {
			display: block;
			background-color: $corporate-white;
			/* YT's actual play button svg */
		}

		.yt-video {
		    background-color: $corporate-black;
		    position: relative;
		    display: block;
		    contain: content;
		    background-position: center center;
		    background-size: cover;
		    cursor: pointer;
		    &::before {
				content: '';
			    display: block;
			    position: absolute;
			    top: 0;
			    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
			    background-position: top;
			    background-repeat: repeat-x;
			    height: 60px;
			    padding-bottom: 50px;
			    width: 100%;
			    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
			}
			/* responsive iframe with a 16:9 aspect ratio
			   thanks https://css-tricks.com/responsive-iframes/
			*/
			&::after {
				content: "";
			    display: block;
			    padding-bottom: calc(100% / (16 / 9));
			}
			&.cursor-disabled {
				cursor: unset;
			}
			@include media-breakpoint-down(sm) {
				height: 250px;
			}
			> {
				iframe {
					width: 100%;
				    height: 100%;
				    position: absolute;
				    top: 0;
				    left: 0;
				    border: 0;
				}
				/* play button */
				.lty-playbtn {
				    display: block;
				    width: 68px;
				    height: 48px;
				    position: absolute;
				    cursor: pointer;
				    transform: translate3d(-50%, -50%, 0);
				    top: 50%;
				    left: 50%;
				    z-index: 1;
				    background-color: transparent;
				    /* YT's actual play button svg */
				    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2OCA0OCI+PHBhdGggZD0iTTY2LjUyIDcuNzRjLS43OC0yLjkzLTIuNDktNS40MS01LjQyLTYuMTlDNTUuNzkuMTMgMzQgMCAzNCAwUzEyLjIxLjEzIDYuOSAxLjU1Yy0yLjkzLjc4LTQuNjMgMy4yNi01LjQyIDYuMTlDLjA2IDEzLjA1IDAgMjQgMCAyNHMuMDYgMTAuOTUgMS40OCAxNi4yNmMuNzggMi45MyAyLjQ5IDUuNDEgNS40MiA2LjE5QzEyLjIxIDQ3Ljg3IDM0IDQ4IDM0IDQ4czIxLjc5LS4xMyAyNy4xLTEuNTVjMi45My0uNzggNC42NC0zLjI2IDUuNDItNi4xOUM2Ny45NCAzNC45NSA2OCAyNCA2OCAyNHMtLjA2LTEwLjk1LTEuNDgtMTYuMjZ6IiBmaWxsPSJyZWQiLz48cGF0aCBkPSJNNDUgMjQgMjcgMTR2MjAiIGZpbGw9IndoaXRlIi8+PC9zdmc+DQogICAg");
				    filter: grayscale(100%);
				    transition: filter .1s cubic-bezier(0, 0, 0.2, 1);
				    border: none;
				}
			}
			/* Post-click styles */
			&.lyt-activated {
			    cursor: unset;
			    &::before, > .lty-playbtn {
					opacity: 0;
				    pointer-events: none;
				}
			}
			&:hover > .lty-playbtn, .lty-playbtn:focus {
				filter: none;
			}
		}
		.lyt-visually-hidden {
		    clip: rect(0 0 0 0);
		    clip-path: inset(50%);
		    height: 1px;
		    overflow: hidden;
		    position: absolute;
		    white-space: nowrap;
		    width: 1px;
		}	
	}
}