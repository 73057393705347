.amd-bs5 {
	.header-icons {
		.shopping-cart {
			.cart-dot {
				background: $primary-red;
				border-radius: 50%;
				height: 11px;
				width: 11px;
				display: inline-block;
				position: absolute;
				left: -3%;
				top: 3%;
			}
			.shopping-cart-dropdown {
				#shoppingCart {
					position: relative;
					&:hover {
						&::after {
							top: 2px;
						}
					}
	
				}
	
				.shopping-cart-container {
					.cartheading-container, .cartbody-container {
						padding: map-get($spacers, 35);
						@include media-breakpoint-up(xl) {
							padding: map-get($spacers, 45) map-get($spacers, 55);
						}
						@include media-breakpoint-between(sm, xl) {
							padding: map-get($spacers, 45);
						}
					}
	
					.cartheading-container {
						align-items: center;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						background-color: $neutral-90;
						color: $header-font;
						box-shadow: $skim;
						height: 3.5rem;
						@include media-breakpoint-up(xl) {
							height: 5.375rem;
						}
						@include media-breakpoint-between(sm, xl) {
							height: 5.5rem;
						}
	
						i, span {
							font-size: map-get($spacers, 45);
						}
	
						button {
							background: none;
							border: none;
							padding: 0;
							color: $header-font;
						}
					}
	
					.cartbody-container {
						position: absolute;
						height: calc(100% - 56px);
						width: 100%;
						overflow-x: hidden;
						overflow-y: auto;
						border: 1px solid;
						@include media-breakpoint-up(xl) {
							height: calc(100% - 86px);
						}
						@include media-breakpoint-between(sm, xl) {
							height: calc(100% - 88px);
						}
						a {
							// color: $access-link-dark !important;
						}
						.spinner-container {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background-color: rgba(255,255,255,.5);
							span {
								font-size: map-get($spacers, 75);
								left: 50%;
								top: 50%;
								display: block;
								position: absolute;
							}
						}
	
						.product-container {
							.product-item {
								margin-bottom: 40px;
	
								.info-col {
									margin-bottom: map-get($spacers, 35);
	
									span, a, button {
										padding-bottom: map-get($spacers, 35);
									}
	
									span {
										display: block;
									}
	
									button {
										background: none;
										border: none;
										padding: 0;
										color: $access-link-dark;
	
										&:hover {
											text-decoration: underline;
										}
									}
	
									.img-wrapper {
										border: none;
										img {
											width: 100%;
										}
									}
	
									.add-remove {
										display: flex;
										justify-content: start;
	
										label {
											max-height: map-get($spacers, 45);
											padding: map-get($spacers, 35) map-get($spacers, 40);
											background-color: $neutral-20;
											line-height: 0.4;
											font-weight: bold;
										}
	
										input {
											max-width: map-get($spacers, 60);
											max-height: map-get($spacers, 45);
											border-color: $neutral-20;
											border: 1px solid $neutral-20;
											box-shadow: inset 0 0 0 0 transparent;
											transition: color .1s ease, border-color .1s ease;
										}
									}
								}
							}
	
							.shipping-details {
								.info-row {
									margin-top: map-get($spacers, 40);
									margin-bottom: map-get($spacers, 40);
	
									[class^='col-'] {
										&:last-of-type {
											//text-align: right;
										}
									}
								}
							}
	
							button.submit, a.btn {
								color: rgb(33, 37, 41);
								background-color: rgb(0, 0, 0, 0);
								border-radius: 2px;
								border: 1px solid black;
								width: 100%;
								justify-content: center;
							}
	
						}
					}
				}
			}
		}
	}

}
