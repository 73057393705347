.amd-bs5 {
	.generic-embed {
		padding-bottom: 100%;
		@include media-breakpoint-up(md) {  	
           	padding-bottom: 45%;
        }
		iframe {
			position: absolute;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    height: 100%;
		    width: 100%;
		    border: 0;
		}
	}
	
}