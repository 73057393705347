.amd-bs5 {
	.divider {
		.divider{
			@include generate-spacing(padding, 40, 'y');
			&.small {
				@include generate-spacing(padding, 35, 'y');
			}
			&.large {
				@include generate-spacing(padding, 45, 'y');
			}
			&.xlarge {
				@include generate-spacing(padding, 60, 'y');
			}
				
			@include media-breakpoint-up(md) {
				@include generate-spacing(padding, 45, 'y');
				&.small {
					@include generate-spacing(padding, 35, 'y');
				}
				&.large {
					@include generate-spacing(padding, 55, 'y');
				}
				&.xlarge {
					@include generate-spacing(padding, 70, 'y');
				}
			}
			hr {
				color: $neutral-80;
				height: 1px;
				margin: 0;
			}
		}
		&.divider-light hr {
			color: $neutral-20;
		}
	}
}
