.amd-bs5 {
	.bc-embed {
		.bc-video {
		    background-color: $corporate-black;
		    position: relative;
		    display: block;
		    contain: content;
		    background-position: center center;
		    background-size: cover;
		    cursor: pointer;
		    &::before {
				content: '';
			    display: block;
			    position: absolute;
			    top: 0;
			    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
			    background-position: top;
			    background-repeat: repeat-x;
			    height: 60px;
			    padding-bottom: 50px;
			    width: 100%;
			    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
			}
			/* responsive iframe with a 16:9 aspect ratio
			   thanks https://css-tricks.com/responsive-iframes/
			*/
			&::after {
				content: "";
			    display: block;
			    padding-bottom: calc(100% / (16 / 9));
			}
			&.cursor-disabled {
				cursor: unset;
			}
			> {
				.bc-advanced {
					width: 100%;
				    height: 100%;
				    position: absolute;
				    top: 0;
				    left: 0;
				    border: 0;
					background-repeat: no-repeat;
					background-size: contain;
					.video-js {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						border: 0;
					}
					/* consent msg */
					.bc-ot-consent {
						display: block;
						position: absolute;
						transform: translate3d(-50%, -50%, 0);
						top: 50%;
						left: 50%;
						z-index: 1;
						background-color: $corporate-white;
						text-align: center;
						/* YT's actual play button svg */
						transition: filter .1s cubic-bezier(0, 0, 0.2, 1);
						border: none;
						width: 100%;
						padding: 15px;
						@include media-breakpoint-down(sm) {
							width: unset;
						}
					}
					/* play button */
					.lty-playbtn {
						display: flex;
						width: 98px;
						height: 98px;
						position: absolute;
						cursor: pointer;
						transform: translate3d(-50%, -50%, 0);
						top: 50%;
						left: 50%;
						z-index: 1;
						background-color: rgba(243,19,19,.45);
						filter: grayscale(100%);
						transition: filter .1s cubic-bezier(0, 0, 0.2, 1);
						border: none;
						border-radius: 50%;
						align-items: center;
						justify-content: center;
						&::before {
							content: '';
							position: absolute;
							display: block;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 15px 0 15px 25px;
							border-color: transparent transparent transparent white;
							left: 50%;
							top: 50%;
							transform: translate(-40%, -50%);
						}
						&::after {
							content: '';
							display: block;
							padding-bottom: calc(100% / (16 / 9));
						}
					}
					&:hover > .lty-playbtn, .lty-playbtn:focus {
						filter: none;
					}
				}
			}
			/* Post-click styles */
			&.lyt-activated {
			    cursor: unset;
			    &::before, > .lty-playbtn {
					opacity: 0;
				    pointer-events: none;
				}
			}
		}
		.lyt-visually-hidden {
		    clip: rect(0 0 0 0);
		    clip-path: inset(50%);
		    height: 1px;
		    overflow: hidden;
		    position: absolute;
		    white-space: nowrap;
		    width: 1px;
		}	
	}
}