@mixin tabs-horizontal {
	.cmp-tabs {
		display: block;
		ol.cmp-tabs__tablist {
			display: flex;
	    	flex-wrap: nowrap;
	    	padding-left: 0;
	    	list-style: none;
			border-bottom: 2px solid $neutral-20;
			border-right: none;
			margin: 0;
			&> li {
				padding: map-get($spacers, 35) 0 0 0;
				margin: 0 map-get($spacers, 45);
				color: $neutral-60;
				border-right: none;
				border-bottom: none;
				flex-basis: auto;
				font-weight: 600;
				&.cmp-tabs__tab--active,
				&:hover,
				&:active {
					color: $corporate-black;
					border-right: none;
				}
				&:after,
				&:hover::after,
				&.cmp-tabs__tab--active:after {
					content: '';
					width: 100%;
					display: block;
					border-right: none;
					padding-top: map-get($spacers, 35);
					border-bottom: 4px solid $corporate-black;
					transform: scaleX(0);
					transition: transform 250ms ease-in-out;
					margin-bottom: -1px;
					height: auto;
					position: relative;
				}
				&:hover::after {
					transform: scaleX(1);
				}
				&.cmp-tabs__tab--active {
					color: $corporate-black;
					&:after {
						border-right: none;
						border-bottom: 4px solid $corporate-black;
						transform: scaleX(1);
					}
				}
			}
		}
	}
}

.amd-bs5 {
	.tabs {
		.btn-Secondary-Accent-Light a,
		.btn-Secondary-Accent-Dark a {
			color: $access-link-dark;
			&:hover,
			&:focus {
				color: $access-link-dark;
			}
		}		
		&.text-light {
			.btn-Secondary-Accent-Light a,
			.btn-Secondary-Accent-Dark a {
				color: $access-link-light;
				&:hover,
				&:focus {
					color: $access-link-light;
				}
			}
		}
		
		.cmp-tabs__tabpanel {
			padding-top: map-get($spacers, 45);
		}

		&.layout-horizontal,
		&.layout-vertical .layout-horizontal {
			@include tabs-horizontal;
		}

		&.layout-vertical,
		&.layout-horizontal .layout-vertical {
			@include media-breakpoint-up(xl) {
				.cmp-tabs {
					display: flex;
					align-items: flex-start;
					gap: map-get($spacers, 45);
					ol.cmp-tabs__tablist {
						flex-wrap: wrap;
						flex-basis: 30%;
						border-bottom: none;
						border-right: 2px solid $neutral-20;
						li {
							color: $neutral-60;
							flex-basis: 100%;
							font-weight: 600;
							margin: map-get($spacers, 40) 0;
							padding: 0 map-get($spacers, 45);
							position: relative;
							&.cmp-tabs__tab--active,
							&:hover,
							&:active {
								color: $corporate-black;
								border-bottom: none;
							}
			    			&:after,
			    			&:hover::after,
							&.cmp-tabs__tab--active:after {
								content: '';
								display: inline-block;
								border-bottom: none;
								border-right: 4px solid $corporate-black;
								padding-left: map-get($spacers, 35);
								position: absolute;
								top: 0;
								bottom: 0;
								right: 0;
								height: var(--height, 0);
								transform: scaleY(0);
								transition: transform 250ms ease-in-out;
							}
							&:hover::after {
								transform: scaleY(1);
							}
							&.cmp-tabs__tab--active {
								color: $corporate-black;
								&:after {
									transform: scaleY(1);
								}
							}
						}
					}
					.cmp-tabs__tabpanel {
						flex-basis: 100%;
					}
				}
			}
			@include media-breakpoint-down(xl) {
				@include tabs-horizontal;
			}
		}

		&.tabs-bg-color {
			color: $corporate-white;
			ol.cmp-tabs__tablist {
				border-color: $neutral-60 !important;
				&> li {
					color: $neutral-40;
					&.cmp-tabs__tab--active,
					&:hover,
					&:active {
						color: $corporate-white !important;
					}
					&:after,
					&:hover::after,
					&.cmp-tabs__tab--active:after {
						color: $corporate-white;
						border-color: $corporate-white !important;
					}
					&.cmp-tabs__tab--active {
						color: $corporate-white !important;
					}
				}
			}
			.cmp-tabs__tabpanel {
				padding-left: map-get($spacers, 45);
				padding-right: map-get($spacers, 45);
			}

			&.layout-vertical {
				padding-top: map-get($spacers, 40);
				ol.cmp-tabs__tablist {
					border-color: $neutral-60 !important;
					li {
						&.cmp-tabs__tab--active,
						&:hover,
						&:active {
							color: $corporate-white;
						}
					}
				}
			}
		}
		
		&.tabs-center {
			ol {
				justify-content: center;
			}
		}

		@include media-breakpoint-down(xl) {
			.cmp-tabs {
				ol {
					flex-flow: row nowrap;
					white-space: nowrap;
					overflow-y: hidden;
    				overflow-x: auto;
    				justify-content: flex-start;
					&::-webkit-scrollbar {
						display: none;
					}
				}
			}
		}
	}
}
