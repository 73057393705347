.amd-bs5 {
	form {
		.form-select.is-invalid, .form-control.is-invalid {
			border-color: $dark-red;
		}
		.invalid-feedback, .cmp-form-error{
			display: block;
			color: $dark-red;
		}
		.required {
			&:after {
				@include font-awesome();
			    font: var(--fa-font-solid);
				content: "\2a";
				vertical-align: 0;
				border: 0;
  				color: $dark-red;
  				font-size: 10px;
			}
		}
		
		.form-control:disabled, .form-control[readonly] {
			background-color: $gray;
			cursor: default;
		}
		
		button {
			margin-top: 0;
		}
		
	}
	
	.form-container-parsys.text-light {
		@include form-light;
		.cmp-form-text__help-block, .password div.form-text {
			color: $gray-500;
		}
	}

}	