.amd-bs5 {
	.anchorBar {
		height: 100%;
		position: sticky;
		top: -.5px;
		z-index: 1020;
		.menu-container {
			background-image: linear-gradient(to right, $corporate-black, $neutral-90,  $corporate-black);
			box-shadow: $box-shadow;
			.navbar {
				padding: 0;
			}
		}
		.row {
			margin: 0;
			width: 100%;
		}
		.container-fluid {
			padding: 0;
		}
	}
	.visible-xs-block {
		display:none;
	}
	@include media-breakpoint-down(xl) {
		.visible-xs-block {
    		display: block !important;
		}
	}
	div.navbar-nav.navbar-anchor-bar {
		width: 100%;
		padding: map-get($spacers, 40) map-get($spacers, 45);
		border-bottom: 1px solid $neutral-80;
		.anchor-bar-text {
    		background-color: transparent;
    		text-transform: uppercase;
    		color: $corporate-white;
    		float: right;
    		padding-right: 5px;
    		font-weight: 500;
    		&:hover {
    	    	background: none;
    		}
    	}
		.anchor-bar-toggle {
			background-color: transparent;
			color: $corporate-white;
			border: none;
			float: right;
			width: map-get($spacers, 50);
			text-align: center;
			padding-top: 3px;
			span.open-menu {
				display: block !important;
			}
			span.close-menu {
				display: none !important;
			}
		}
		&.active .anchor-bar-toggle{
			span.open-menu {
					display: none !important;
				}
				span.close-menu {
					display: block !important;
				}
		}
	}
	.anchor-bar-menu {
		padding: 0;
		background-color: $neutral-20;
		box-shadow: $box-shadow;
		max-height: 0px;
    	transition: max-height 0.5s ease-in-out;
    	overflow: hidden;
		ul.navbar-nav {
			padding-top: map-get($spacers, 45);
			padding-bottom: map-get($spacers, 45);
			margin-bottom: 0;
			> li {
				padding: 0 map-get($spacers, 45);
				> a {
					margin-bottom: map-get($spacers, 40);
					padding: 0;
					color: $corporate-black;
					font-weight: $font-weight-normal;
					text-align: right;
					text-decoration: none;
					&:hover, &.active {
		    			background-color: transparent;
		    		}
				}
			}
		}
		&.active {
			background-color: $neutral-20;
			box-shadow: $box-shadow;
    		display: block!important;
    		padding: 0;
    		max-height: 65em;
    		transition: max-height 1s ease-in-out;
		}
	}
	@include media-breakpoint-up(xl) {
		.anchorBar {
			position: sticky;
			top: -.5px;
			z-index: 1020;
			.menu-container {
				background-image: linear-gradient(to right, $corporate-black, $neutral-90,  $corporate-black);
				border-bottom: 1px solid $neutral-80;
				height: 100%;
				.navbar {
					padding-top: 0;
					padding-bottom: 0;
					border-bottom: none;
				}
			}
			.row {
				margin: 0;
				width: 100%;
			}
			.container-fluid {
				padding: 0;
			}
		}
		.anchor-bar-menu, .anchor-bar-menu.active {
			display: flex !important;
			background-color: transparent;
			box-shadow: none;
			max-height: none;
			overflow: visible;
			justify-content: center;
			ul.navbar-nav {
				flex-direction: row;
				justify-content: center;
				padding-top: 0;
				padding-bottom: 0;
				> li {
					border: none;
					padding: 0;
					&:not(:last-child) {
						padding-right: map-get($spacers, 50);
					}
					> a {
						padding-top: map-get($spacers, 40);
						padding-bottom: 0;
						margin-bottom: 0;
						color: $corporate-white;
						text-decoration: none;
						font-weight: $font-weight-normal;
		    			&:after {
							display: block;
							content: '';
							border-bottom: 2px solid $corporate-white;
							transform: scaleX(0);
							transition: transform 250ms ease-in-out;
							padding-top: map-get($spacers, 40);
						}
						&:hover::after {
							transform: scaleX(1);
						}
						&.active:after {
							transform: scaleX(1);
						}
					}
				}
			}
		}
		.vertical-anchor-bar {
			padding-top: map-get($spacers, 55);
			.menu-container {
				background-image: unset;
				border-bottom: unset;
				box-shadow: unset;
				.navbar {
					.row {
						border-left: 2px solid $neutral-20;
						.visible-xs-block {
							display: block;
							border-bottom: unset;
							padding: unset;
							padding-left: map-get($spacers, 45);
							.anchor-bar-toggle {
								display: none;
							}
							.anchor-bar-text {
								color: $corporate-black;
								float: none;
								padding-bottom: map-get($spacers, 35);
							}
						}
						.anchor-bar-menu {
							justify-content: left;
							ul.navbar-nav {
								flex-direction: column;
								li {
									&:not(:last-child) {
										padding-right: unset;
									}
									a {
										color: $access-link-dark;
										padding: map-get($spacers, 35) map-get($spacers, 35) map-get($spacers, 35) map-get($spacers, 45);
										text-align: left;
										&:after {
											display: none;
										}
										&:hover, &.active {
											box-shadow: inset 3px 0 0 $access-link-dark;
										}
									}
								}
							}
						}
						
					}
				}
			}
		}
	}
	:target {
		scroll-margin-top: 116px;
	}
}