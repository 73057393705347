.amd-bs5 {
  .events {
    .card {
      @include generate-spacing(margin, 45, 'b');
      @include teaser-card;
      box-shadow: $lifted;
      border-radius: $border-radius-lg
    }
    flex-direction: row;
    time {
      font-weight: bold;
    }
    @include horizontal-card();
    .event.horzlayout {
      flex-direction: row;
    }
    @include media-breakpoint-down(xl) {
		.event.horzlayout {
      		flex-direction: column;
      		.horzimage {
				width: 100%;
				img {
					height: auto;
					width: 100%;
				}
			}
			.event.horzcontent {
				width: inherit;
                .card-body {
                  padding: 1rem;
                }
			}
    	}
	}
  }
}