// Disable Sticky footer for template editor
.aem-AuthorLayer-Layouting, .aem-AuthorLayer-structure, .aem-AuthorLayer-initial {
	.amd-bs5 {
	
    .root-flex-wrapper {
		>.cmp-container >.aem-Grid{
			min-height: unset;
			}
		}
	}
}

.amd-bs5 {
	
	// Sticky footer
    .root-flex-wrapper {
		>.cmp-container >.aem-Grid{
			display: flex;
			min-height: 100vh;
			flex-direction: column;
			
			>.content-wrapper {
				flex: 1;
			}
		}
	}
	
	footer.common-footer {
		background-color: $font-dark;
		color: $font-light;
		a:not(.cmp-title__link):not(.btn a):not(footer.common-footer .social-menu >li a) {
			@include medium();
			color: $neutral-40;
			text-decoration: none;
			&:hover {
				color: $font-light;
				text-decoration: underline;
			}
		}
		a {
			&.cmp-title__link {
				color: $font-light;
				outline: none;
				width: 100%;
				display: inline-block;
				&:hover {
					color: $font-light;
					text-decoration: none;
				}
				@include media-breakpoint-up(md) {
					pointer-events: none;
					display: inline;
				}
			}
		}
		section {
			&.top-menu {
				.marketo-embed {
					form.mktoForm {
						.mktoAsterix {
							color: $primary-red;
						}
						.mktoButton {
							border: 2px solid $corporate-white;
							color: $corporate-white;
							&:hover {
								background-color: $corporate-white;
								color: $corporate-black;
							}
						}
						input.mktoInvalid, select.mktoInvalid, textarea.mktoInvalid, .mktoLogicalField.mktoInvalid {
							border: 1px solid $primary-red;
						}
					}
				}
			}
			&.menu {
				ul {
					list-style: none;
					padding-left: 0;
				}
			}
			&.middle-menu {
				@include media-breakpoint-down(sm) {
					// Text next to an active title shows.
					.title {
						.cmp-title__link {
							&:after {
			                    content: "\f078";
			                    @include font-awesome();
			                    font: var(--fa-font-sharp-solid);
			                    float: right;
			                    transition-duration: .3s;
			                    transition-property: transform;
			                }
			                &:focus::after {
			                    transform: rotate(180deg);
			                }
						}
						&:focus-within + .text {
						    height: auto;
						}
					}
					
					.text {
						height: 0;
						overflow: hidden;
						transition: height 2s;
						
						&:focus-within {
							height: auto;
						}
					}
				}
				li {
					padding-bottom: 0.6rem;  
					line-height: 1rem;
				}
			}
		}
	}
}