.amd-bs5 {
	.pullquote {
        &.text-small {
            aside.pullquote {
                figure {
                    @include body();
                    font-weight: 700;
                }
            }
        }
        &.text-large {
            aside.pullquote {
                figure {
                    @include jumbo();
                    font-size: 3.125rem;
                    line-height: 3.5rem;
                    font-weight: 500
                }
            }
        }
		aside.pullquote {
	        @include generate-spacing(margin, 55, 'b');
	        @include generate-spacing(padding, 45, 't');
	        figure {
	            border-left: 5px solid $teal;
	            margin-left: -5px;
	            @include xlarge();
	            @include generate-spacing(margin, 35, 'b');
	            margin-bottom: .5rem;
	            @include generate-spacing(padding, 40, 's');
	        }
	        figcaption {
	            color: $neutral-60;
	            font-style: italic;
	            @include body();
	            @include generate-spacing(padding, 40, 's');
	        }
	        @include media-breakpoint-up(lg) {
	            @include generate-spacing(margin, 65, 's');
	            @include generate-spacing(margin, 65, 'e');
	        }
		}
		&.teal {
			aside.pullquote {			
				figure {
		            border-left-color: $teal;
		        }
			}
		}
		&.teal-dark {
			aside.pullquote {			
				figure {
		            border-left-color: $primary-teal-dark;
		        }
			}
		}
		&.red {
			aside.pullquote {			
				figure {
		            border-left-color: $primary-red;
		        }
			}
		}
		&.orange {
			aside.pullquote {			
				figure {
		            border-left-color: $primary-orange;
		        }
			}
		}
		&.gold {
			aside.pullquote {			
				figure {
		            border-left-color: $primary-gold;
		        }
			}
		}		
	}
    .pullquote.text-light > aside.pullquote {
		figure, figcaption {
			font-weight: 500;
		}
		figcaption {
			color: $neutral-40;
		}
	}
}
