.amd-bs5 {
	.title {
	    // the h3 inclusion is a fix for migrated pages
		&.expressive {
			h2, h3 {
				@include h2-expressive;
			}
		}
        &.expressiveReg {
            h2, h3 {
                @include h2-expressiveReg;
            }
        }
        &.expressiveSmall {
            h2, h3 {
                @include h2-expressiveSmall;
            }
        }        
        &.titleJumbo {
            .cmp-title {
                h2.cmp-title__text, h3.cmp-title__text {
                    font-size: clamp(2.5rem, 1.098rem + 3.902vw, 5rem);
                    line-height: normal;
                    @include klavika-condensed();
                    font-weight: medium;
                }
            }
        }
        &.text-Teal {
            .cmp-title {
                .cmp-title__text {
                    color: $primary-teal;
                }
            }
        }
        &.text-Teal-dark {
            .cmp-title {
                .cmp-title__text {
                    color: $primary-teal-dark;
                }
            }
        }
        &.text-Orange {
            .cmp-title {
                .cmp-title__text {
                    color: $primary-orange;
                }
            }
        }
        &.text-Red {
            .cmp-title {
                .cmp-title__text {
                    color: $primary-red;
                }
            }
        }
        &.text-contrastGrey {
            .cmp-title {
                .cmp-title__text {
                    color: $neutral-50;
                }
            }
        }
        &.text-gold {
            .cmp-title {
                .cmp-title__text {
                    color: $primary-gold;
                }
            }
        }
        @include h1-h6;
	}
}