.amd-bs5 {
	$default-color: $primary-teal-dark;
	$alert-color: $lightening-yellow;
	$success-color: $light-green;
	$warning-color: $primary-red;
	.cmp-toast {
		.cmp-toast--container {
			background-color: $neutral-10;
			display: flex;
			@include medium();
			@include generate-spacing(padding, 40, 'xy');
			@include generate-spacing(margin, 40, 'b');
			border: 1px solid $neutral-20;
			border-left: 3px solid $default-color;
			.cmp-toast--text {
				p {
					margin-bottom: 0;
					+ ul, + ol {
					    @include generate-spacing(margin, 40, 't');
				    }
				}
			}
			.semi-bold {
				font-weight: 600;
			}
			i {
				@include generate-spacing(padding, 40, 'e');
				color: $default-color;
			}
			&.toast-alert {
				border-left-color: $alert-color;
				i {
					color: $alert-color;
				}
			}
			&.toast-success {
				border-left-color: $success-color;
				i {
					color: $success-color;
				}
			}
			&.toast-warning {
				border-left-color: $warning-color;
				i {
					color: $warning-color;
				}
			}
		}
	}

	.cmp-toast_dark {
		.cmp-toast {
			.cmp-toast--container {
				color: $corporate-white;
				background-color: $neutral-90;
				border-color: $primary-teal;
				i {
					color: $primary-teal;
				}
				&.toast-alert {
					border-color: $alert-color;
					i {
						color: $alert-color;
					}
				}
				&.toast-success {
					border-color: $success-color;
					i {
						color: $success-color;
					}
				}
				&.toast-warning {
					border-color: $warning-color;
					i {
						color: $warning-color;
					}
				}
				a {
					color: $access-link-light;
				}
			}
		}
	}
}