// AUTHORING page styles
.aem-AuthorLayer-Edit  {
    .amd-bs5 {
        .container-parsys {
            .gallery {
                .gallery-container {
                    display: grid;
                    grid-template-columns: repeat(13, 7%);
                    align-items: center;
                    .galleryImage {
                        width: 100px;
                        height: auto;
                        position: static;
                        @include generate-spacing(padding, 30, 'xy');
                        img {
                            visibility: visible;
                        }
                    }
                }
                &.thumbSmall {
                    .gallery-container {
                        grid-template-columns: repeat(6, 16%);
                        .galleryImage {
                            width: 200px;
                        }
                    }
                }
                &.thumbMed {
                    .gallery-container {
                        grid-template-columns: repeat(5, 20%);
                        .galleryImage {
                            width: 250px;
                        }
                    }
                }
                &.thumbLg {
                    .gallery-container {
                        grid-template-columns: repeat(4, 25%);
                        .galleryImage {
                            width: 325px;
                        }
                    }
                }
                &.thumbXlg {
                    .gallery-container {
                        grid-template-columns: repeat(3, 33.33%);
                        .galleryImage {
                            width: 450px;
                        }
                    }
                }
            }
        }
    }
}
// published page styles
.amd-bs5 {
    .container-parsys {
        .gallery {
            .gallery-container {
                position: relative;
                @include generate-spacing(margin, 55, 'b');
                .galleryImage {
                    @include generate-spacing(padding, 0, 'b');
                    position: absolute;
                    overflow: hidden;
                    img {
                        visibility: hidden;
                    }
                    .cmp-image {
                        .cmp-image__image {
                            -webkit-transition: 0.6s ease;
                            transition: 0.6s ease;
                        }
                        .cmp-image__image:hover {
                            -webkit-transform: scale(1.2);
                            transform: scale(1.2);
                        }
                    }
                }
            }
            .modal {
                @include modal;
            }
        }
        &.title-left {
            .modal {
                .modal-dialog {
                    .cmp-image__title {
                        @include modal-title;
                        text-align:left;
                    }
                }
            }
        }
        &.title-center {
            .modal {
                .modal-dialog {
                    .cmp-image__title {
                        @include modal-title;
                        text-align: center;
                    }
                }
            }
        }
        &.title-right {
            .modal {
                .modal-dialog {
                    .cmp-image__title {
                        @include modal-title;
                        text-align: right;
                    }
                }
            }
        }
    }
}