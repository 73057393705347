.amd-bs5 {
	footer.common-footer {
	    .social-menu {
			@include media-breakpoint-up(md) {
				float: right;
			}
			>li {
				display: inline-block;
				a {
					@include large();
					color: $font-light;
					@include generate-spacing(margin, 35, 'b');
					&:hover {
						background-color: $neutral-80;
						border-radius: 5px;
						@include generate-spacing(padding, 25, 'y');
					}
				}
				&:not(:last-child) {
					@include generate-spacing(padding, 30, 'e');
					@include media-breakpoint-between(md,lg) {
						@include generate-spacing(padding, 40, 'e');
					}
					@include media-breakpoint-up(lg) {
						@include generate-spacing(padding, 45, 'e');
					}
				}
			}
			.icon {
	            display: inline-block;
	            width: map-get($spacers, 55);
	            @include font-awesome();
	            font: var(--fa-font-brands);
	        }
	        .linkedin {
	            &:before {
	                content: "\f0e1";
	            }
	        }
	        .twitter {
	            &:before {
	                content: "\f099";
	            }
	        }
	        .xtwitter {
	            &:before {
	                content: "\e61b";
	            }
	        }
	        .facebook {
	            &:before {
	                content: "\f39e";
	            }
	        }
	        .youtube {
	            &:before {
	                content: "\f167";
	            }
	        }
	        .newsletter {
				font: var(--fa-font-sharp-solid);
	            &:before {
	                content: "\f0e0";
	                
	            }
	        }
	        .weixin {
	            &:before {
	                content: "\f1d7";
	            }
	        }
	        .instagram {
	            &:before {
	                content: "\f16d";
	            }
	        }
 			.twitch {
	            &:before {
	                content: "\f1e8";
	            }
	        }
	        .youku {
				width: 6.25rem;
				font: unset;
	            &:before {
	               content: url("/content/dam/code/images/footer/youku.webp");
	            }
	        }
	        .weibo {
	            &:before {
	                content: "\f18a";
	            }
	        }
	        .bilibili {
	            &:before {
	                content: "\e3d9";
	            }
	        }
	        ul.dropdown-menu {					
				li ul.dropdown-menu-content {
					li {
						a {
							@include medium();
							margin-bottom: 0;
							padding: map-get($spacers, 25);
						}
					}							
				}
			}
		}
	}
}
	