.amd-bs5 {
  .markdown-render {
    blockquote {
      margin-left: 2rem;
    }
    table td {
      padding: .3rem;
    }
    dt, dd {
      display: block;
    }
    dd {
      margin-left: 2rem;
    }
    pre {
      margin: 2rem 1rem;
      &>code {
        color: white;
        display: block;
        padding-left: 1rem;
        overflow-x: scroll;
      }
    }
  }
}