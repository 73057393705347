$profile-image-padding: map-get($spacers, 45);
.amd-bs5 {
	.teaser {
		--tag-background-color: #{$neutral-50};
		@include generate-spacing(margin, 60, 'b');
		height: 100%;

		// teaser and teaser-default
		.cmp-teaser {
			display: contents; // Makes this container basically disappear.
		}

		&.teaser-default {
			color: $corporate-black;
			a {
				color: $access-link-dark;
			}
		}
		// teaser-light only
		&.teaser-light {
			.card {
				--tag-background-color: #{$neutral-80};
				background: rgba($neutral-90, .8);
				backdrop-filter: blur(5px);
			}

			.card-heading-link,
			.card-body,
			.card-text {
				color: $font-light;
			}

			a {
				color: $access-link-light;
			}
		}


		.card {
			background: $corporate-white;
			@include teaser-card;

			&:hover,
			&:focus-within {
				box-shadow: $lifted;
			}

			.horzlayout {
				height: 100%;
				display: flex;
				flex-direction: column;
			}

			.horzimage {
				@include teaser-card-image();
			}

			.card-image-link {
				&:hover {
					img {
						opacity: 1;
					}
				}
				@include teaser-card-image();
			}

			.horzcontent {
				display: flex;
				flex-grow: 1;
			}

			.card-body {
				@include teaser-card-body();
				@include media-breakpoint-down(sm) {
					padding-bottom:map-get($spacers-clamped, 50 );
				}
			}
			.card-title {
				@include teaser-title();
			}

			.card-heading-link {
				color: inherit;
				text-decoration: none;
				@include media-breakpoint-down(sm) {
					@include generate-spacing(padding, 25, 'b');
				}
				&:hover {
					text-decoration: underline;
				}
			}

			.card-text {
				@include teaser-card-text();
			}

			.card-links {
				display: flex;
				flex-direction: column;
				justify-self: flex-end;
				text-wrap: pretty;
			}

			.card-link {
				@include secondary-button();
				font-size: $font-size-sm;
				@include generate-spacing(margin, 0, 's');
			}

			.teaser-tags {
				display: flex;
				justify-content: flex-start;
				column-gap: map-get($spacers, 30);

			}

			.teaser-tag {
				background-color: var(--tag-background-color);
				@include small();
				color: $corporate-white;
				@include generate-spacing(margin, 40, 't');
				@include generate-spacing(margin, 30, 'b');
				@include generate-spacing(padding, 30, 'x');
				@include generate-spacing(padding, 25, 'y');
			}

			.cmp-image {
				position: relative;
				@include youtube-icons;
				@include media-breakpoint-down(xl) {
					.youtube-logo {
						width: clamp(3.875rem, 2.192rem + 4.683vw, 6.875rem);
						height: clamp(2.8rem, 1.987rem + 2.263vw, 4.25rem);
					}
				}
			}
		}

		// both teaser-product and teaser profile
		&.teaser-profile,
		&.teaser-product {
			.card {
				background: transparent;
				border: none;
				border-radius: 0;
				box-shadow: none;
				backdrop-filter: none;
				&:hover,
				&:focus-within {
					box-shadow: none;
				}

				.card-image-link {
					.card-img-top {
						border-radius: 0;
					}
				}

				.card-body {
					@include generate-spacing(padding, 0, 'xy');
					.card-title {
						@include h2-expressiveSmall;
					}
				}


				.card-text {
					@include generate-spacing(padding, 35, 'b');
				}

				h3 {
					@include generate-spacing(padding, 35, 'b');
				}

				img.cmp-image__image, .youtube-logo {
					margin-bottom: $profile-image-padding;
				}
				.yt-play-button {
					margin-top: -#{$profile-image-padding};
				}
				.card-link {
					width: max-content;
					text-align: left;
				}
			}

			&.teaser-light {
				.card {
					background: transparent;
				}
			}
		}

		// teaser-profile only
		&.teaser-profile {
			.card {
				&:hover,
				&:focus-within {
					.cmp-image__image {
						transform: none;
					}
				}

				.cmp-image__image {
					@include generate-spacing(padding, 45, 'b');
					@include generate-spacing(margin, 0, 'b');

					&:hover {
						transition: none;
						transform: none;
					}
				}

				p {
					@include body();
				}
				.card-links {
					.card-link {
						font-size: $font-size-base;
						text-wrap: pretty;
					}
				}
			}

			&.teaser-light {
				.card {
					background: transparent;
				}
			}
		}

		// teaser-product only
		&.teaser-product {
			.card {
				p {
					@include medium();
				}
				.card-links {
					text-align: center;
					.card-link {
						font-size: $font-size-base;
						&:first-child {
							margin: 1rem 0;
							border: 1px solid #737478;
						}
					}
				}
			}
			&.teaser-light {
				.card-links {
					font-size: unset;
					text-align: center;
				}
				.card-link {
					color: $corporate-white;
					&:first-child:hover {
						color: $corporate-black;
					}
				}
			}
		}



		// horizontal only
		@include media-breakpoint-up(xl) {
			&.horizontal {
				.card {
					@include horizontal-card();

					&.teaser-profile, &.teaser-product {
						.card-body {
							@include generate-spacing(padding, 40, 's');
						}
					}
				}
			}
		}
	}
	.teaser-tags {
		display: flex;
		justify-content: flex-start;
		column-gap: map-get($spacers, 30);
	}
	.teaser-tag {
		background-color: var(--tag-background-color);
		@include small();
		color: $corporate-white;
		@include generate-spacing(margin, 40, 't');
		@include generate-spacing(margin, 30, 'b');
		@include generate-spacing(padding, 30, 'x');
		@include generate-spacing(padding, 25, 'y');
	}
}
