@import "@glidejs/glide/src/assets/sass/glide.core";
@import "@glidejs/glide/src/assets/sass/glide.theme";

.amd-bs5 {
    .cmp-solutionselector {
		--glide-arrows-top: 38%;
		padding-bottom: map-get($spacers, 55);
		/**
		  * Trick to get Full Width Containers in Limited Width Parents. 
		  * The idea here is: push the container to the exact middle of the browser window with left: 50%;, 
		  * then pull it back to the left edge with negative -50vw margin.
		  */
		@include media-breakpoint-down(xl) {
			width: 100vw;
			position: relative;
			left: 50%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
			.controls-label {
	            display: none;
	        }
		}
		@include media-breakpoint-up(xl) {
			.glide__bullets {
				padding-right: 0;
	    		padding-left: 0;
    		}
		}
		&.glide--swipeable {
			cursor: auto;
		}
		a {
			color: $neutral-20;
			&:hover, &:focus {
				color: $corporate-white
			}
		}
		
        .glide__slide {
			display: flex;
			min-height: 400px;
            overflow: hidden;
            position: relative;
            height: 0;
			&--active {
	            height: 100%;
			}
			
			.cmp-solutionselector__content {
				display: flex;
				position: relative;
			    z-index: 1;
			    color: $corporate-white;
				flex-direction: column;
				justify-content: flex-end;
				@include media-breakpoint-up(md) {
					justify-content: center;
				}
				&__title, &__description{
					margin-bottom: map-get($spacers, 40);
				}
				&__title {
					@include klavika();
					font-weight: 500;
				}
				
			}
			.cmp-solutionselector__image {
				position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
			    
			    &>figure {
					height: 100%;
					&>picture>img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				  
				}
				
			}
        }
        .glide__arrow {
			@include carousel-arrows();
		}
		.glide__bullets {
			.glide__bullet {
				&.glide__bullet--active {
		            &::after {
		                transition: none;
		            }
		        }
			}
		}
    }
    .solutionselector_light {
		.cmp-solutionselector {
			.glide__bullets {
				.glide__bullet {
					color: $neutral-40;
			        &:hover, &:active, &:focus, &.glide__bullet--active  {
			            color: $corporate-black;
			        }
			        &::after {
		            	background-color: $corporate-black;
		            }
		            &::before {
						 background-color: $neutral-40;
					}
				}
			}
		}
	}
}