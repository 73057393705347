.aem-AuthorLayer-Edit  {
	.xf-web-container {
		.amd-bs5 {
			header {
				&.common-header {
					.nav-mega {
						.mega-menu {
							// To allow Header component authoring
							top: 200px;
						}
					}
				}
			}
		}
	}
}

.amd-bs5 {
    header {
		&.common-header {
			.nav-mega {
				.mega-menu {
					.cmp-headermenu {
						box-shadow: $floating;
    					border-radius: 0 0 10px 10px;
						.cmp-headermenu__tablist {
							display: none;
							@include media-breakpoint-up(xl) {
								display: flex;
								flex-wrap: wrap;
								padding-left: 0;
							    list-style: none;
							    // use custom rem instead of spacial system
								width: 17.625rem;
								color: $corporate-white;
								align-self: stretch;
								padding-bottom: map-get($spacers, 45);
								&:after{
									z-index: -1;
								    content: '';
								    position: absolute;
								    width: 100%;
								    height: 100%;
								    background-color: rgb(26, 26, 27, 0.63);
								    backdrop-filter: blur(30px);
								    left: 0;
								    top: 0;
								    border-radius: 0 0 10px 10px;
								  }
							}
							
							.cmp-headermenu__tab {
							    box-sizing: border-box;
							    border-bottom: 1px solid transparent;
							    padding: .5rem 1rem;
							    cursor: pointer;
							
							    &--active {
							        border-color: inherit;
							    }
							}

							//Add Hover effect to submenu links
							button.header-menu-links {
								border-bottom: 1px solid rgba(71, 73, 78, 0.53);
								&:hover, &:focus {
									background-color: rgba(55, 55, 55, 0.43);
								}
								
							}
							
							.cmp-headermenu__tab.header-menu-links.active, .show>.cmp-headermenu__tab.header-menu-links, .cmp-headermenu__tab--active {
								background-color: $corporate-white;
								color: $corporate-black;
								font-weight: 600;
								box-shadow: inset -5px 4px 10px 0 rgb(0,0,0,.24);
								.chevron-right-icon {
						            @include font-awesome();
				    				font: var(--fa-font-sharp-solid);
						            float: right;
						            &:before {
						                content: "\f105";
						            }
						        }
							}
							
						}
						
						.cmp-headermenu__tabcontent {
							flex: 1;
							background: linear-gradient(15deg, $neutral-10 0%, rgb(255, 255, 255) 50.13%, $neutral-10 100%);
							border-radius: 0 0 10px 0;
							align-self: stretch;
							
							.cmp-headermenu__tabpanel {
								display: block;
								@include media-breakpoint-up(xl) {
							    	display: none;
							    }
							    &--active {
							        display: block;
							    }
							}
							
							.cmp-headermenu__accordionheading {
								padding: 0;
								.header-menu-links {
									background-color: $neutral-80 !important; 
									color: $corporate-white !important; 
									border-bottom: 1px solid rgb(50 50 56 / 67%) !important;
								}
							}
							
							.cmp-headermenu__accordionbody {
								padding: map-get($spacers, 40) map-get($spacers, 45);
								p {
									margin-bottom: 1.2rem;
									font-size: 0.938rem;
									line-height: 1.5;
								}
								a {
									&:hover {
										text-decoration: underline;
									}
								}
								ul {
									padding-bottom: 1rem;
									padding-left: 0;
									li {
										list-style: none;
										padding-top: .35rem;
										padding-bottom: 0.35rem;
										font-size: .9rem;
										line-height: 1.3rem;
									}
								}
								h6 {
									font-size: map-get($spacers, 40);
									line-height: 1.3rem;
									margin-bottom: .35rem;
								}
								.columns {
									.row {
										@include media-breakpoint-down(xl) {
											row-gap: 0;
										}
									}
								}
								
								
							}
							@include media-breakpoint-up(xl) {
								
								.cmp-headermenu__accordionheading {
					                display: none;
					            }
					            .collapse{
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}
}
