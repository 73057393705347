
/* Customize the Coveo Theme variables
 * https://docs.coveo.com/en/atomic/latest/usage/themes-and-visual-customization/#theme-variables 
 */

:root {
	--atomic-font-family: 'Open Sans', arial, sans-serif;
	--atomic-primary: #{$corporate-black};
	--atomic-primary-light: #{$neutral-60};
	--atomic-on-background: #{$neutral-90};
	--atomic-ring-primary: #{$neutral-20};
	--atomic-primary-dark: #{$neutral-80};
	
	.amd-bs5 {
		atomic-no-results::part(icon) {
			max-width: 10rem;
		}
		
		atomic-facet.hide-count::part(value-count) {
			visibility: hidden;
		}
			
		atomic-search-layout{
			atomic-layout-section[section='status'] {
				.coveo-sort {
					grid-area: atomic-sort;
    				justify-self: end;
    				min-width: 50px;
				}
				.coveo-sort-hidden {
                    display: none;    				
				}
			}
			
			atomic-layout-section[section='pagination'] {
				flex-direction: column;
			}
			
			
			
			atomic-folded-result-list::part(outline), atomic-result-list[display="list"]::part(outline) {
			    border: 1px solid var(--atomic-neutral);
				border-radius: 1rem;
			    @include generate-spacing(padding, 40, 'xy');
			   	@include generate-spacing(margin, 40, 'b');
			   	&:before {
					display: none;
				}
			}

			atomic-result-list[display="grid"]::part(outline) {
				pointer-events: none;
				padding: 0;
				--atomic-border-radius: 5px;
				--atomic-border-radius-md: 5px;
				--atomic-border-radius-lg: 5px;
				--atomic-border-radius-xl: 5px;
				// Below padding exactly matches the Coveo default.
				--atomic-padding: 1rem;
				border-radius: var(--atomic-border-radius);
			   	@include generate-spacing(margin, 40, 'b');
			   	&:before {
					display: none;
				}
				overflow: hidden;
				box-shadow: $skim;
				&:hover, &:focus-within {
					box-shadow: $lifted;
					.cmp-image__image {
						transform: scale(1.1);
					}
				}
				
			}
			
			atomic-result-list[display="grid"]::part(result-list-grid-clickable) {
				display: none;
			}
		}
	}

}