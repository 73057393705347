.amd-bs5 {
  .amd-feedback-ratings {
  	input:focus {
		border-color: $access-link-dark;
	}
  	input:checked + label {
		i {
			&.fa-smile {
				color: green;
			}
			&.fa-meh {
				color: gold;
			}
			&.fa-frown {
				color: red;
			}
		}
	}
	i {
		font-size: map-get($display-font-sizes, 5);
		padding: 3px 3px 2px 3px;
		background-color: white;
		border-radius: 50%;
		color: $primary-teal-dark;
		cursor: pointer;
		filter: drop-shadow(1px 1px 3px gray);
	}
    
  }
}