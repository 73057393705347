.amd-bs5 {
    ul.blog-listing {
        padding-left: 0;
        li {
            list-style-type: none;
            margin-bottom: 1rem;
        }
        .blog-time {
            color: $neutral-60;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            column-gap: map-get($spacers, 35);
            .fass {
                @include generate-spacing(padding, 25, 't');
            }
        }
        &.blog-listing-skeleton {
            .skeleton-blog-time-text, .blog-link-fallback {
                height: 1rem;
                background-color: #ddd;
                animation: pulse-bg 1s infinite;
                border-radius: $border-radius-lg;
            }
            .skeleton-blog-time-text {
                width: 6rem;
                @include generate-spacing(margin, 50, 'b');
            }
            .blog-link-fallback {
                width: 100%;
                max-width: 100%;
                @include generate-spacing(margin, 35, 'b');
            }
        }
    }
    @keyframes pulse-bg {
        0% {
          background-color: #ddd;
        }
        50% {
          background-color: #d0d0d0;
        }
        100% {
          background-color: #ddd;
        }
      }
}
