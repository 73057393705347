.amd-bs5 {
    .bigNumber {
        margin-top: 3.5rem;
        margin-bottom: 3.5rem;

        // Default Styles (No Arrow Present)
        .top-text,
        .number,
        .afterText,
        .beforeText,
        .bottom-text,
        .disclaimer,
        sup {
            color: $corporate-black;
        }

        &.size-jumbo {
            .number {
                @include jumbo();
            }
        }

        .number {
            @include h2-expressive();
            color: inherit;
            display: inline;
            margin: 0px;
        }


        // Common Styling for Multiple Elements
        .top-text,
        .beforeText,
        .afterText,
        .bottom-text {
            @include subheadline();
            margin: 0px;
        }

        sup {
            font-size: .75em;
            line-height: 0;
            position: relative;
            vertical-align: baseline;
        }


        .disclaimer {
            font-style: italic;
            @include small();
        }

        .arrow-container {

            .arrow-down-svg,
            .arrow-up-svg {
                display: none !important;
            }
        }

        // Arrow-Specific Styles
        &.arrow-up,
        &.arrow-down {

            .top-text,
            .number,
            .afterText {
                @include klavika-condensed();
            }
        }

        &.arrow-up {
            .arrow-down-svg {
                display: none !important;
            }

            .arrow-up-svg {
                display: block !important;
            }

            .top-text {
                font-size: 26px;
                margin-bottom: 10px;
            }

            .number {
                font-size: 100px;
            }

            .afterText {
                font-size: 26px;
            }

            .arrow-container svg {
                margin-top: -107px;
                margin-right: -82px;
            }
        }

        &.arrow-down {
            .arrow-down-svg {
                display: block !important;
            }

            .arrow-up-svg {
                display: none !important;
            }

            .top-text {
                font-size: 24px;
                margin-bottom: 5px;
            }

            .number {
                font-size: 90px;
            }

            .afterText {
                font-size: 50px;
                position: relative;
                top: -27px;
            }

            .arrow-container svg {
                margin-right: -90px;
            }
        }

        .arrow-container {
            position: relative;

            svg {
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                height: 75px;
                aspect-ratio: 1 / 1;

                path {
                    fill: $corporate-black;
                }
            }
        }

        // *** Text Color Adjustments Based on "All Copy Color" ***
        &.text-light {

            .top-text,
            .afterText,
            .beforeText,
            .bottom-text,
            .disclaimer,
            sup,
            .number {
                color: $corporate-white;
            }

            .arrow-container svg path {
                fill: $corporate-white;
            }
        }

        &.orange {

            .top-text,
            .afterText,
            .beforeText,
            .bottom-text,
            .disclaimer,
            sup,
            .number {
                color: $primary-orange;
            }

            .arrow-container svg path {
                fill: $primary-orange;
            }
        }

        &.gold {

            .top-text,
            .afterText,
            .beforeText,
            .bottom-text,
            .disclaimer,
            sup,
            .number {
                color: $primary-gold;
            }

            .arrow-container svg path {
                fill: $primary-gold;
            }
        }

        &.teal {

            .top-text,
            .afterText,
            .beforeText,
            .bottom-text,
            .disclaimer,
            sup,
            .number {
                color: $primary-teal;
            }

            .arrow-container svg path {
                fill: $primary-teal;
            }
        }

        &.teal-dark {

            .top-text,
            .afterText,
            .beforeText,
            .bottom-text,
            .disclaimer,
            sup,
            .number {
                color: $primary-teal-dark;
            }

            .arrow-container svg path {
                fill: $primary-teal-dark;
            }
        }

        // *** Number Only Color Overrides ***
        &.number_text-light {
            .number {
                color: $corporate-white !important;
            }
        }

        &.number_orange {
            .number {
                color: $primary-orange !important;
            }
        }

        &.number_gold {
            .number {
                color: $primary-gold !important;
            }
        }

        &.number_teal {
            .number {
                color: $primary-teal !important;
            }
        }

        &.number_teal-dark {
            .number {
                color: $primary-teal-dark !important;
            }
        }
    }
}