.amd-bs5 {
    .oms-cart-button {
		a {
			width: 100%;
		}
		ul.dropdown-menu {
			background-color: inherit;
			border-radius: 2px;
			backdrop-filter: blur(30px);							
			padding: 0;
			list-style-type: none;
			margin: 0;
			li {
				padding: 0;
				border-bottom: 1px solid rgba(71, 73, 78, 0.53);				
				&:last-child {
					border-bottom: none;
					
				}
				a {
					border: 0;
					padding: map-get($spacers, 35) map-get($spacers, 35);
					&:hover, &:focus {        
				    	background-color: $corporate-black;
				    	
				    }
				}
			}							
		}						
	}
	
	.btn-Primary-White .oms-cart-button ul.dropdown-menu li a {
		&:hover, &:focus {        
	    	background-color: $corporate-white;
	    	
	    }
	}

	.btn-Secondary-Accent-Dark, .btn-Secondary-Accent-Light, .btn-Secondary-Black, .btn-Secondary-White  {
		.oms-cart-button ul.dropdown-menu li a {
			&:hover, &:focus {        
		    	background-color: transparent;
		    	
		    }
		}
	}
}