.amd-bs5 {
	.text {
		@include h1-h6;
	    // the h3 inclusion is a fix for migrated pages
		h2, h3 {
			.expressive {
				@include h2-expressive;
			}
            .expressiveReg {
                @include h2-expressiveReg;
            }
            .expressiveSmall {
                @include h2-expressiveSmall;
            }                   
		}
		p {
            .subheadline {
                @include subheadline;
            }
			span.expressive {
				@include h2-expressive;
			}
			span.expressiveReg {
				@include h2-expressiveReg;
			}
		}
		&.headerNoWrap {
			table {
				tr {
					white-space: nowrap;
				}
			}
		}

		&.rowNoWrap {
			table {
				td {
					white-space: nowrap;
				}
			}
		}
		&.no-bullets {
		  ul {
		    list-style-type: none;
		    padding-left: 0;
		    li {
		      margin-bottom: map-get($spacers, 35);
		      Line-height: 1.5rem;
		    }
		  }
		}		
		.table-wrapper {
			position: relative;
			.table-responsive {
				table {
					position: relative;
					u {
						text-decoration: none;
					}
					p {
						margin-bottom: 0;
					}
				}
				&.shadow-left:before, &.shadow-right:after, &.shadow-both:before, &.shadow-both:after {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					width: 15px;
					z-index: 1;
				}
				&.shadow-left:before, &.shadow-both:before {
					left: 0;
					background: linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0));
				}	
				&.shadow-right:after, &.shadow-both:after {
					right: 0;
					background: linear-gradient(to left, rgba(0,0,0,0.2), rgba(0,0,0,0));
				}		
			}
		}	
		.fa-up-right-from-square {
			padding-left: .25rem;
		}	
	}		
}