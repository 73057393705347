.amd-bs5 {
    .heroCarousel {
        .glide.paused {
            .glide__bullet--active:after {
                width: 100%;
                transition: none;
            }
        }
        .glide__slide {
            overflow: hidden;
        }
        .glide__arrows {
            display: none;
        }
        .glide__pause {
            position: absolute;
            bottom: 9.3rem;
            border-radius: 50%;
            background-color: transparent;
            aspect-ratio: 1/1;
            width: 3em;
            border: 2px solid $corporate-white;
            border: 2px solid rgba(116, 117, 121, .7);
            color: $neutral-50;
            backdrop-filter: blur(30px);

        @include media-breakpoint-down(xs) {
               	right: 1.3em;
            }
            @include media-breakpoint-up(sm) {
            	right: 2.2em;
            }          
            @include media-breakpoint-up(xl) {
               	right: 4em;
            }
        }
        .fa-play:before {
		    margin-left: 4px;
		}
			    
        @include media-breakpoint-down(md) {
            .controls-label {
                display: none;
            }
            .glide__arrows {
                display: block;
            }
        }
        .cmp-slideThumb-off {
            .glide__track {
                ul {
                    @include generate-spacing(margin, 0, 'b');
                }
            }
        }
        &.middle-AlignOverride {
            .cmp-herocarousel {
                .glide__track {
                    .glide__slides {
                        .glide__slide {
                            .carouselslide {
                                .carousel-slide {
                                    justify-content: center;
                                    @include media-breakpoint-down(md) {
                                        justify-content: flex-end;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}