@mixin group-expand {
	@include font-awesome();
	font: var(--fa-font-sharp-solid);
	float: right;
	&:before {
		content: "\f078";
	}
}
@mixin group-collapse {
	.chevron-up-down-icon {
		&:before {
			content: "\f077";
		}
	}
}

.amd-bs5 {
	.cmp-navigation {
		width: 100%;
		&.scroll-bar {
			overflow-y: auto;
			height: 100vh;
		}
	}
	.navigation {
		display: flex;
		background-color: $neutral-90;
		.mobile-navigation-bar {
			display: none;
		}
		.top-content {
			padding: map-get($spacers, 40) map-get($spacers, 40) 0 map-get($spacers, 45);
		}
		.navigation-content {
			display: block;
		}
		.cmp-navigation__group {
			list-style: none;
			padding-left: 0;
			.cmp-navigation__item-link {
				color: $corporate-white;
				text-decoration: none;
			}
			li {
				display: flex;
				flex-direction: column;
				a {
					padding: map-get($spacers, 40) map-get($spacers, 40) map-get($spacers, 40) 0;
					box-shadow: inset 3px 0 0 transparent;
					&:hover {
						background-color: $corporate-black;
						box-shadow: inset 3px 0 0 $primary-teal;
					}
					&[aria-expanded="true"], &.active {
						background-color: $corporate-black;
						@include group-collapse;
					}
					.chevron-up-down-icon {
						@include group-expand;
					}
				}
				&.cmp-navigation__item--level-0 {
					>a {
						padding-left: map-get($spacers, 45);
					}
				}
				&.cmp-navigation__item--level-1, &.cmp-navigation__item--level-2 {
					>a {
						padding-left: map-get($spacers, 40);
					}
				}
				.navigation-group {
					padding-left: map-get($spacers, 45);
					border-bottom: 1px solid $neutral-60;
					.navigation-group {
						padding-left: map-get($spacers, 40);
					}
				}
			}
		}
	}
	@include media-breakpoint-down(xl) {
		.navigation {
			.overflow-auto {
			height: 100%;
		}
			.cmp-navigation {
				display: flex;
				flex-direction: column;
				.mobile-navigation-bar {
					display: block;
					float: right;
					color: $corporate-white;
					padding: map-get($spacers, 40) map-get($spacers, 45);
					text-align: right;
					.chevron-up-down-icon {
						@include group-expand;
					}
					&[aria-expanded="true"] {
						@include group-collapse;
					}
					>span {
						margin-left: 5px;
						padding-top: 3px;
						width: map-get($spacers, 50);
						text-align: center;
					}
				}
				.top-content {
					display: none;
				}
				.navigation-content {
					height: 100vh;
					overflow-y: auto;
					&:not(.show) {
						display: none;
					}
				}
			}
		}
	}
}
.aem-AuthorLayer-Edit .cmp-navigation.scroll-bar {
	overflow-y: unset;
	height: unset;
}