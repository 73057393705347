.marketo-embed{
	form.mktoForm {
	    width: 100% !important;
	    font-family: inherit !important;
	    color: inherit !important;
	    
	    input[type="date"],
		input[type="email"],
		input[type="number"],
		input[type="tel"],
		input[type="text"],
		input[type="url"],
		select.mktoField,
		textarea.mktoField {
			@include body;
		    line-height: inherit;
		    box-shadow: none;
		}
		
		.mktoFormRow,
		.mktoButtonRow,
		.mktoFormCol,
		.mktoFieldWrap,
		input[type=url],
		input[type=text],
		input[type=date],
		input[type=tel],
		input[type=email],
		input[type=number],
		textarea.mktoField,
		select.mktoField,
		.mktoLogicalField {
			width: 100% !important;
		}
		
		input[type=checkbox] {
			font-size: 1.14rem;
			&:focus {
				box-shadow: $shadow-field-focus;
			}
			&:checked {
				background-color: $neutral-80;
				border-color: $border-color;
			}
		}
		
		.mktoFormRow {
			display: flex;
			flex-direction: column;
			@include media-breakpoint-up(lg) {
				flex-direction: unset;
				div.mktoFieldDescriptor:not(:first-of-type){
					padding-left: 15px;
				}
			}
			
			.mktoFormCol{
				flex: 1;
				margin-bottom: map-get($spacers, 40) !important;
			}
		    
		    .mktoFieldWrap {
				.form-control {
    				padding: 0.375rem 0.75rem;
    				width: 100%;
    				@include border-radius(2px);
    				&:focus {
						border-color: $border-color;
						box-shadow: 0 0 0 0.15rem rgb(157, 159, 162, .75);
					}
				}
				input[type="radio"], select {
				    padding: 5px;
				}
				.form-select {
					font-size: 1rem;
					line-height: 1.5;
					padding: .375rem 2.25rem .375rem .75rem;
					width: 100%;
					@include border-radius(2px);
					&:focus {
						border-color: $border-color;
						box-shadow: $shadow-field-focus;
					}
				}
				
			}
			
			.mktoRadioList {
				width: 100% !important;
			}
			
			fieldset {
				background: $corporate-white;
				border: 1px solid $neutral-20;
			    @include border-radius(2px);
				padding: map-get($spacers, 35) !important;
			    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
				label.mktoLabel, .mktoLogicalField {
					width: auto !important;
				}
				legend {
					margin: 0;
				}
			}
		    
		}
		
		.mktoOffset {
		    height: 0;
		}
		
		label {
			float: unset !important;
		    width: auto !important;
		    margin-bottom: map-get($spacers, 30) !important;
		    @include body;
		    line-height: 1rem;
		}
		
		.mktoRequiredField label.mktoLabel,
		.mktoForm label {
		    font-weight: inherit !important;
		}
		
		.mktoAsterix {
			color: $primary-magenta;
			@include body;
			line-height: 1rem;
			margin-bottom: 0;
		}
		
		.mktoHtmlText {
			width: 100% !important;
			@include body;
		}
		
		&.mktoLayoutAbove {
			.mktoRequiredField {
				.mktoAsterix {
				    float: right;
				    padding-left: map-get($spacers, 25);
				    padding-right: 0;
				}
			}
			
		}
		
		.mktoButtonWrap {
		    margin-left: 0 !important;
		}
		
		.mktoButtonRow {
			padding-top: map-get($spacers, 35);
		}
		
		input.mktoInvalid, select.mktoInvalid, textarea.mktoInvalid, .mktoLogicalField.mktoInvalid {
			border: 1px solid $primary-magenta;
		}
		
		input.mktoField.form-text {
			@include media-breakpoint-down(xs) {
				height: inherit;
			}
		}
		
		.mktoErrorArrow {
			background-color: $primary-magenta;
		}
		
		.mktoErrorMsg {
			background-color: $primary-magenta;
			background-image: none;
			border: none;
			@include border-radius(2px);
			-webkit-box-shadow: $box-shadow-sm; 
			box-shadow: $box-shadow-sm;
			text-shadow: none;
			@include body;
		}
		
		.mktoGutter.mktoHasWidth {
			display: none;
		}
		
		.mktoButton:focus {
			box-shadow: $shadow-field-focus;
		}

		.mktoCheckboxList {
			input[type=checkbox] {
				margin-top: map-get($spacers, 25);
			}
			label {
				line-height: 1.5rem;
				padding-bottom: map-get($spacers, 10);
			}
		}
	}
	.success-message{
		display: none;
		padding: map-get($spacers, 40);
		font-size: map-get($spacers, 40);
		p.subheadline {
			@include subheadline;
		}
	}
}

.embed.text-light{
	.marketo-embed {
		form.mktoForm {
			.mktoFormRow {
				input.mktoInvalid {
					border-width: 3px;
				}
				input,
				input[type=checkbox],
				select,
				textarea,
				fieldset {
					background-color: $neutral-20;
					border-color: $gray-500;
				}
				input:checked {
					background-color: $corporate-black;
					border-color: $corporate-white;
				}
				fieldset {
					color: $corporate-black;
					input[type=checkbox] {
						background-color: $corporate-white;
						&:checked {
							background-color: $corporate-black;
						}
					}
				}
			}
			.mktoButtonRow {
				.mktoButton.btn {
					@include accordion-btn-primary;
				}
			}
		}
	}
}
