.amd-bs5 {
	section[data-component="video"] {
		&:has(.controls) {
			position: relative;
		}
		.overlay-play {
			display: none;
			&.playing {
				display: none;
			}
			padding: 0;
	}
	video {
		width: 100%;
		}

	}
	video[data-controls] + .overlay-play {
		display: block;
	}
	[data-component="video"] {
		&:hover, &:focus, &:focus-within, &:active {
			.controls {
				opacity: 1;
				transition: opacity .2s;
			}
		}
	}
	@include youtube-icons;
	video[data-controls] + .overlay-play + .controls {
		display: flex;
	}
	.controls {
		.video-progress {
			@include generate-spacing(padding, 40, 'x');
			flex-basis: 90%;
			progress {
				width: 100%;
			}
		}
		button {
			width: 42px;
			border: none;
			background: none;
			&:focus {
				border: solid 1px blue;
			}
			&:hover, &:focus {
				text-shadow: silver 3px 0 15px;
			}
		}
		display: none;
		padding: 0 1rem;
		opacity: 0;
		transition: opacity 1s ease-out;
		position: absolute;
		bottom: 5px;
		left: 0;
		right: 0;
		font-size: 16px;
		line-height: 36px;
		justify-content: flex-start;
		flex-wrap: nowrap;
		width: 100%;
		background: linear-gradient(rgba(212,212,212,.4), rgba(212,212,212,.9));
	}
}
