.amd-bs5 {
	footer.common-footer {
		.bottom-menu {
			ul {
				>li {
					display: block;
					a {
						@include small();
					}
					@include media-breakpoint-up(md) {
						display: inline-block;
						&.links {
							&:after {
								content: "|";
								color: $neutral-40;
								margin-left: 5px;
							}
						}
					}
				}
			}
			.copyright {
				@include small();
				color: $neutral-40;
			}
		}
	}
}