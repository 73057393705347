.amd-bs5 {
  .imageComparison {
    --position: 50%;
    .image-comparison-container .image-item, img {
      width: 100%;
      height: 100%;
    }

    .image-comparison-container {
      display: flex;
      position: relative;
    }

    .left.image-item {
      position: absolute;
      width: var(--position);
    }

    img {
      object-fit: cover;
      object-position: left;
    }

    .image-compare-slider {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
    }

    .slider-icon {
      position: absolute;
      top: 50%;
      left: var(--position);
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: map-get($spacers, 55 );
      height: map-get($spacers, 55 );
      border-radius: 50%;
      background: $corporate-white;
    }

    .slider-line {
      position: absolute;
      height: 100%;
      width: 3px;
      background: $corporate-white;
      left: var(--position);
      transform: translateX(-50%);
    }
  }
}
