// AUTHORING page styles
.aem-AuthorLayer-Edit  {
    .amd-bs5 {
		.animatedHotspots {
			.hotspots-container {
				min-height: 600px;
			}
		}
	}
}

// LIVE page styles	
body>.popover.fade {
	.hotspots-popover {
		img {
			max-width: 100%;
		}
		.inner-content {
			@include generate-spacing(padding, 40, 'xy');
			box-shadow: $box-shadow;
			h6.popoverTitle {
				@include generate-spacing(margin, 35, 'b');
			}
			a {
				color:$access-link-dark;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.amd-bs5 {
	.animatedHotspots {
		.sprite-parent {
			position: sticky;
			top: 0;
		}

		// Hotspot buttons
		.hotspots {
			display: none;
			position: relative;
			z-index: 2;
			height: 100%;
			width: 100%;

			button.hotspot {
		    	.content {
		        	display: none;
		    	}
			}

			.hotspot {
		    	position: absolute;
		    	cursor: pointer;
		    	border: none;
				width: 34px;
		    	height: 34px;
		    	background-color: transparent;
		    	&,
		    	.icon:after,
		    	.icon:before,
		    	.spot {
		        	transform: translateX(-50%) translateY(-50%);
		        	transition-property: transform;
		        	transition-duration: 0.2s;
		    	}

		    	.spot:hover {
		        	transition: .15s ease-in-out;
		        	outline: 0;
		    	}

		    	.icon {
		        	left: 50%;
		        	top: 50%;
		        	position: absolute;
		        	z-index: 2;

		        	&:after,
		        	&:before {
		            	content: '';
		            	position: absolute;
		            	left: 50%;
		            	top: 50%;
		            	bottom: auto;
		            	right: auto;
		            	transform: translateX(-50%) translateY(-50%);
		            	background-color: rgb(0, 0, 0);
		        	}
		    	}

		    	&.active .icon:before {
		        	transform: translateX(-50%) translateY(-50%) rotate(45deg);
		    	}

		    	.icon:after {
		        	height: 2px;
		        	width: 12px;
		    	}

		    	.icon:before {
		        	height: 12px;
		        	width: 2px;
		    	}

		    	.spot {
		        	transform: translateX(-50%) translateY(-50%) scale(1);
		        	border-radius: 50%;
		        	background-color: #fff;
		        	width: 26px;
		        	height: 26px;
		        	left: 50%;
		        	top: 50%;
		        	position: absolute;
		        	z-index: 1;
		        	transition-property: transform;
		        	transition-duration: 0.2s;
		        	outline: 5px solid #00000054;
		    	}

		    	&:hover .spot {
		        	transform: translateX(-50%) translateY(-50%) scale(1.4);
		        	outline: 0px;
		    	}

		    	&:after {
		        	content: '';
		        	position: absolute;
		        	z-index: 1;
		        	width: 100%;
		        	height: 100%;
		        	top: 0;
		        	left: 0;
		        	border-radius: 100%;
		        	background-color: transparent;
		        	animation: pulse 2s ease-out infinite;
		    	}

		    	@keyframes pulse {
		        	0% {
		            	transform: scale(0.7);
		            	box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.8);
		        	}
		        	60% {
		            	transform: scale(2);
		            	box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0);
		        	}
		    	}

				@keyframes hotspot-init {
			    	0% {
			        	transform: translateX(-50%) translateY(-50%) scale(3);
			        	opacity: 0;
			    	}
			    	100% {
			        	transform: translateX(-50%) translateY(-50%) scale(1);
			        	opacity: 1;
			    	}
				}
            }
        }


		// Mobile

		.container {
			@include generate-spacing(padding, 0, 'x');
			.row {
				.col {
					.hotspot-mobile-image {
						width: 100%;
					}
					.glide {
						margin-top: 1rem;
						.glide__track {
							.glide__slides {
								align-items: center;
								.glide__slide {
									background-color: $corporate-white;
									color: $corporate-black;
									border-radius: .375rem;
									box-shadow: $box-shadow;
									border: 1px solid rgba(0,0,0,.2);
									@include medium;
									@include generate-spacing(padding, 40, 'xy');
									div:first-child:not(:only-child) {
										font-weight: bold;
										text-transform: uppercase;
										@include generate-spacing(margin, 30, 'b');
									} 
								}
							}
						}
						.glide-bullets {
							text-align: center;

							button {
								width: 10px;
								height: 10px;
								border-radius: 100%;
								border: 0;
								background-color: $gray-500;
								@include generate-spacing(padding, 0, 'xy');
								@include generate-spacing(margin, 25, 's');
								@include generate-spacing(margin, 25, 'e');

								&.glide__bullet--active {
									background-color: $gray-600;
								}
							}
						}
					}
				}
			}
		}
			
		.hotspot-carousel.pointer-event {
			touch-action: pan-y;
		}
		
		&.text-light {
			button.glide__bullet--active {
				background-color: $corporate-black;
			}
		}
	}
}