// AUTHORING page styles
.aem-AuthorLayer-Edit  {
    .amd-bs5 {
        .container-parsys {
            .hero-content-right {                
                div {
                    min-width: 150px!important
                }
            }
        }
    }
}

// Published page styles
.amd-bs5 {
    .heroExpressive {
        .heroBasic {
            .container-lg {
                @include generate-spacing(padding, 45, 't');
                .breadcrumb {
                    padding-top: 0;
                }	
                h1 {
                    margin-bottom: 0;
                }
            }
        }
        .container-lg {
            @include generate-spacing(padding, 0, 't');
        }
        .title {
            @include generate-spacing(padding, 0, 'b');
            @include media-breakpoint-up(md) {
                @include generate-spacing(padding, 40, 'b');
            }
        }
        .pre-title {
            @include medium();
        }
        .sub-title {
            @include large();
        }
        .disclaimer {
            color: $neutral-60;
            font-style: italic;
            @include body();
        }
        &.text-light {
            .disclaimer {
                font-weight: 500;
                color: $neutral-40;
            }
        }
        .hero-content {
            >.row {
                align-items: center;
                min-height: 13.75rem;
            }
            .image {
                @include generate-spacing(padding, 40, 'b');
            }
        }
        &.height-small {
            .hero-content {
                >.row {
                    min-height: 8.75rem;
                }
            }
        }
        &.height-large {
            .hero-content {
                >.row {
                    min-height: 28.75rem;
                }
            }
        }
        &.content-center {
            .hero-content {
                >.row {
                    justify-content: center;
                }
            }
        }

        .hero-content-container {
            display: flex;
            @include media-breakpoint-down(md) {
                flex-direction: column;
                .hero-content-left {
                    order:2;
                }
                .hero-content-right {
                    order:1;
                    padding-left: 0!important;
                    padding-right: 0!important;
                }
            }
            .hero-content-left {
                padding-right: 1rem;
            }
            .hero-content-right {
                display: flex;
                padding-left: calc(var(--bs-gutter-x)*.5);
                padding-right: calc(var(--bs-gutter-x)*.5);
                > .align-self-center {
                    flex-grow: 1;
                }
            }
        }
        

        @include media-breakpoint-up(md) {
            .hero-content {
                @include generate-spacing(padding, 40, 't');
                >.row {
                    min-height: 18.75rem;
                }
            }
            &.height-small {
                .hero-content {
                    >.row {
                        min-height: 10rem;
                    }
                }
            }
            &.height-large {
                .hero-content {
                    >.row {
                        min-height: 33.75rem;
                    }
                }
            }
        }
        @include media-breakpoint-up(xl) {
            .hero-content {
                @include generate-spacing(padding, 70, 's');
            }
            .hero-content-hasPadding {
                @include generate-spacing(padding, 70, 'e');
            }
            &.content-center {
                .hero-content {
                    @include generate-spacing(padding, 0, 's');
                }
            }
        }
        @include media-breakpoint-down(sm) {
            &.mobile-image {
                .hero-content {
                    >.row {
                        align-items: flex-end;
                        min-height: 33.75rem;
                    }
                }
            }
        }
        .hero-content {
            img {
                height: 100%;
                width:100%
            }
        }
        &.content-bg-white .hero-content-background {
            background-color: $corporate-white;
        }
        &.content-bg-white20 .hero-content-background {
            background-color: rgba($corporate-white, 0.2);
        }
        &.content-bg-white70 .hero-content-background {
            background-color: rgba($corporate-white, 0.7);
        }
        &.content-bg-pearlgrey .hero-content-background {
            background-color: $neutral-10;
        }
        &.content-bg-cloudgrey20 .hero-content-background {
            background-color: $neutral-20;
        }
        &.content-bg-darkgrey80 .hero-content-background {
            background-color: $neutral-80;
        }
        &.content-bg-charcoalgrey90 .hero-content-background {
            background-color: $neutral-90;
        }
        &.content-bg-black .hero-content-background {
            background-color: $corporate-black;
        }
        &.content-bg-black20 .hero-content-background {
            background-color: rgba($corporate-black, 0.2);
        }
        &.content-bg-black70 .hero-content-background {
            background-color: rgba($corporate-black, 0.7);
        }
        &:not([class*="content-bg"]) .container-fluid {
            padding: 0 !important;
        }
    }
}