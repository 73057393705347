.amd-bs5 {
	.footnotes {
		--primary: #{$corporate-white};
		--secondary: #{$neutral-80};
		&.footnote-dark-text .footnote-details-container {
			--primary: #{$corporate-black};
			--secondary: #{$neutral-20};
		}
		.footnote-container {
			background-color: $neutral-90;
			width: 100%;
			overflow: hidden;
			color: $corporate-white;
			// Keep this to hide shadow content on mobile IOS
            details summary::-webkit-details-marker { display: none; }
		}
		details {
			width: 100%;
			background-color: $neutral-95;
			color: $corporate-white;
			&> summary {
				cursor: pointer;
				list-style-type: none;
				font-weight: 600;
				color: $corporate-white;
				&:hover {
					span {
						text-decoration: underline;
					}
				}
				.fa-chevron-up {
					transition-duration: 0.3s;
					transition-property: transform;
					transform: rotate(180deg);
				}
				.label {
					padding-right: 13px;
					font-weight: 500;
				}
				.container {
					@include generate-spacing(padding, 40, 'y');
				}
			}
			.footnote-details-container {
				width: 100%;
				color: var(--primary);
				background-color: var(--secondary);
			}
		}
		details[open] {
			&> summary {
				margin-bottom: 11px;
			}
			.fa-chevron-up {
				transform: rotate(0deg);
			}
            .footnote-details-container {
                .container {
                    .row {
                        .column {
                            // the h3 inclusion is a fix for migrated pages
                            h2, h3 {
                                .expressive {
                                    @include h2-expressive;
                                }
                                .expressiveReg {
                                    @include h2-expressiveReg;
                                }
                            }
                            p {
                                .subheadline {
                                    @include subheadline;
                                }
                            }
                        }
                    }
                }
            }
		}
        .footnote-details-container {
            .container {
                .row {
                    .col {
                        // the h3 inclusion is a fix for migrated pages
                        h2, h3 {
                            .expressive {
                                @include h2-expressive;
                            }
                            .expressiveReg {
                                @include h2-expressiveReg;
                            }
                        }
                        p {
                            .subheadline {
                                @include subheadline;
                            }
                        }
                        a {
							color: $access-link-light;
						}
                    }
                }
            }
        }
	}
}
