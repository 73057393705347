.amd-bs5 {
	.customModal {
		.modal {
			.modal-dialog {
                &.small {
                    max-width: 40%;
                }
                &.medium {
                    max-width: 50%;
                }

                &.modal-lg {
                    max-width: 75%;
                }

                &.modal-xl {
                    max-width: 90%;
                }

                .modal-content {
                    background-color: unset;
					border: unset;
                }

				.modal-header {
					border: none;
					padding: 0;
					position: absolute;
					top: map-get($spacers, 30);
					right: map-get($spacers, 30);
					z-index: 1;
					button.btn {
						margin: 0;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 0;
						background-color: $corporate-white;
						border: 2px solid $corporate-black;
						width: 42px;
						height: 42px;
						font-size: 1.6rem;
						box-shadow: 0 0 10px 0 rgb(0 0 0 / 40%);
						cursor: pointer!important;
						&:hover {
							background-color: $corporate-black;
							border: 2px solid $corporate-white;
						}
					}
				}
				.modal-body {
					padding: 0;
				}
			}
		}
	}
}