// AUTHORING page styles
.aem-AuthorLayer-Edit  {
    .amd-bs5 {
        .container-parsys {
            .logoWall {
                .logo-images {
                    display: grid;
                    grid-template-columns: repeat(10, 10%);
                    align-items: center;
                    .image {
                        .logo-images__item {
                            img {
                                visibility: visible;
                            }
                            .logo-image__image {
                                width: 100px;
                                height: auto;
                                position: static;
                                @include generate-spacing(padding, 30, 'xy');
                            }
                        }
                    }
                }
                &.thumbSmall {
                    .logo-images {
                        grid-template-columns: repeat(8, 12%);
                        .logo-image__image {
                            width: 130px;
                        }
                    }
                }
                &.thumbMed {
                    .logo-images {
                        grid-template-columns: repeat(6, 16%);
                        .logo-image__image {
                            width: 160px;
                        }
                    }
                }
                &.thumbLg {
                    .logo-images {
                        grid-template-columns: repeat(6, 16%);
                        .logo-image__image {
                            width: 180px;
                        }
                    }
                }
            }
        }
    }
}

// published page styles
.amd-bs5 {
    .container-parsys {
        .logoWall {
            .logo-images {
                @include generate-spacing(margin, 0, 'xy');
                @include generate-spacing(padding, 0, 'xy');
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                .image {
                    @include generate-spacing(padding, 0, 'b');
                    .logo-images__item {
                        img {
                            visibility: hidden;
                        }
                        a.cmp-image__link {
                            opacity: 1;
                            transition: opacity 0.2s ease-in-out;
                        }
                        a.cmp-image__link:hover {
                            opacity: .5;
                        }
                    }
                    .logo-image__image {
                        @include generate-spacing(margin, 35, 'xy');
                    }
                }
            }
        }
    }
}