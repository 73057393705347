
.glide__bullets.primary__bullets {
    display: grid;
    grid-auto-flow: column;
    grid-gap: clamp(0.5rem, -2.625rem + 10vw, 3rem);
    height: 6rem;
    width: 100%;
    position: relative;
    transform: none;
    bottom: auto;
    left: auto;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    @include media-breakpoint-down(xs) {
       	padding-right: 1.3em;
    	padding-left: 1.3em;
    	grid-gap: clamp(1rem, -2.625rem + 10vw, 1.5rem);
    }
    @include media-breakpoint-up(sm) {
    	grid-gap: clamp(1.2rem, -2.625rem + 10vw, 1.5rem);
    	padding-right: 2.2rem;
    	padding-left: 2.2rem;
    }          
    @include media-breakpoint-up(xl) {
       	padding-right: 4rem;
    	padding-left: 4rem;
    }    
    .glide__bullet {
		box-shadow: none;
        border-radius: 0;
        position: relative;
        border: none;
        background: transparent;
        color: $neutral-60;
        height: 3rem;
        margin: 0;
        padding: 0;
        line-height: initial;
        width: auto;
        text-align: start;
        &:hover, &:active, &:focus {
            color: $corporate-white;
        }
        &.glide__bullet--active {
            color: $corporate-white;
            &::after {
                width: 100%;
                transition: width 11s;
            }
        }
        &::before {
            // Grey
            height: 2px;
            background-color: $neutral-60;
            position: absolute;
            top: 0;
            content: '';
            width: 100%;
            right: 0;
        }
        &::after {
            // White
            height: 2px;
            background-color: $corporate-white;
            z-index: 10;
            top: 0;
            position: absolute;
            content: '';
            transition: none;
            width: 0;
            left: 0;
        }
    }
    @include media-breakpoint-down(md) {
        .controls-label {
            display: none;
        }
    }
}

.carousel-skeleton-loader {
	display: flex;
    gap: 10px;
    overflow: hidden;
    .carousel-skeleton-card {
		flex: 1;
        height: 26rem;
        background-color: #ddd;
        animation: pulse-bg 1s infinite;
        border-radius: $border-radius-lg;
        width: 6rem;
        @include generate-spacing(margin, 50, 'b');
    }
}

@keyframes pulse-bg {
    0% {
      background-color: #ddd;
    }
    50% {
      background-color: #d0d0d0;
    }
    100% {
      background-color: #ddd;
    }
  }
