.amd-bs5 {
	.breadcrumb {
	    @include generate-spacing(padding, 40, 'y');
	    margin-bottom: 0;
	    color: $neutral-50;
	    @include small();
	    text-align: left;
	    &.text-light {
			.cmp-breadcrumb {
				a {
					color: $light;
					&:hover {
						color: $light;
						text-decoration: underline;
					}
				}
			}
		}
		.cmp-breadcrumb {
			.container-fluid {
				padding-top: 0;
				padding-bottom: 0;
			}
			ol {
				margin-bottom: 0;
				padding-left: 0;	
			}
		    a {
				text-decoration: none;
				color: $neutral-50;
				&:hover {
					text-decoration: underline;
					color: $neutral-40;
				}
			}
			
			.cmp-breadcrumb__item--active {
				font-weight: 600;
			}
	
	    	.cmp-breadcrumb__item {
		        & + .cmp-breadcrumb__item:before {
		            @include font-awesome();
				    font: var(--fa-font-sharp-solid);
					content: "\f105";
					vertical-align: 0;
					border: 0;
		            @include generate-spacing(padding, 25, 'x');
		        }
			}
		}
	}
}
