@import "@glidejs/glide/src/assets/sass/glide.core";
@import "@glidejs/glide/src/assets/sass/glide.theme";

.amd-bs5 {
	[id^="imageModal-imageThumbnailCarousel-"] {
		.btn-close {
			color: #e1e1e2;
			opacity: 1;
			padding: 2.265rem;
		}

		.download {
			color: #e1e1e2;
			font-size: 2rem;
			opacity: 1;
			padding: 2.265rem;
		}
		
		.modal-dialog {
			@include media-breakpoint-up(xl) {
				max-width:70%;
			}
		}
	}
    .cmp-imagethumbnailcarousel {
		--glide-arrows-top: unset;
		padding-bottom: map-get($spacers, 55);

		&.glide--swipeable {
			cursor: auto;
		}
		
        .glide__slide {
			display: flex;
			min-height: 95px;
            overflow: hidden;
            position: relative;
            height: 0;
			&--activemainimage {
	            height: 100%;
	            img {
					border:3px solid $corporate-black;
				}
			}
			
			.cmp-imagethumbnailcarousel__image {
				position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
			    cursor: pointer;

			    &>figure {
					height: 100%;
					&>picture>img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						user-drag: none;
	                    -webkit-user-drag: none;
	                    user-select: none;
	                    -moz-user-select: none;
	                    -webkit-user-select: none;
	                    -ms-user-select: none;
					}
				}
			}
        }
        
        .cmp-imagethumbnailcarousel__mainimage {
			justify-content: center;
			align-items: center;
			height: 400px;
			width:100%;
			cursor: pointer;
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
			}
			.cmp-image {
				max-height: 100%;
				@include media-breakpoint-down(md) {
					.clkImageZoomBtn {
						display: none;
					}
				}
				img {
					max-height: 400px;
				}
			}
			
		}
		
        .glide__arrow {
			bottom: 4.75rem;
			@include carousel-arrows();
			@include media-breakpoint-up(xl) {
				padding: 0;
				&--next {
					right: 0;
				}
				&--prev {
					left: 0;
				}
				
			}
		}
    }
}