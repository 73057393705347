.amd-bs5 {
    .heroCarousel {
        .carouselslide {
            position: relative;
            --base-z-index: 0;
            @include media-breakpoint-only(xxl) {
                @include OverRide(50%, 50%);
            }
            @include media-breakpoint-only(xl) {
                @include OverRide(70%, 70%);
            }
            @include media-breakpoint-only(lg) {
                @include OverRide(50%, 50%);
            }
            @include media-breakpoint-up(xl) {
                aspect-ratio: 5/1.6;
            }
            @include media-breakpoint-between(md, xl) {
                aspect-ratio: 16/10;
            }
            @include media-breakpoint-between(sm, md) {
                aspect-ratio: 2/2;
            }
            @include media-breakpoint-down(sm) {
                aspect-ratio: 10/16;
            }
            @media (prefers-reduced-motion) {
                video {
                    display: none;
                }
            }
            .carousel-video {
                video {
                    height: 100%;
                    width: auto;
                    @include media-breakpoint-down(lg) {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
                .carousel-background {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
                position: relative;
                z-index: var(--base-z-index);
                height: 100%;
                width: auto;
            }
            .carousel-slide {
                position: absolute;
                bottom: 0;
                z-index: calc(var(--base-z-index) + 1);
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: 100%;
                height: 100%;
                &.middle-Align {
                    justify-content: center;
                }
                &.left-gradient.bottom-gradient {
                    @include media-breakpoint-up(md) {
                        background-image: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%), linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 85%, rgba(0,0,0,1) 100%);
                    }
                    @include media-breakpoint-down(lg) {
                        background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 85%, rgba(0,0,0,1) 100%);
                    }
                }

                &.left-gradient:not(.bottom-gradient) {
                    @include media-breakpoint-up(md) {
                        background-image: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
                    }
                }

                &.bottom-gradient:not(.left-gradient) {
                    background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 85%, rgba(0,0,0,1) 100%);
                }

                .container-lg {
                    @include generate-spacing(padding, 0, 'y');
                    max-width: unset;
                    @include media-breakpoint-up(xl) {
                        @include generate-spacing(padding, 65, 'x');
                    }
                    @include media-breakpoint-between(sm, xl) {
                        @include generate-spacing(padding, 50, 'x');
                    }
                    @include media-breakpoint-down(sm) {
                        @include generate-spacing(padding, 40, 'x');
                    }


                    h2.carousel-title {
                        @include jumbo();
                        color: $corporate-white;
                        z-index: calc(var(--base-z-index) + 1);
                        @include media-breakpoint-up(xl) {
                            font-size: clamp(2.39rem, 0.705rem + 2.247vw, 4.3rem);
                            line-height: clamp(2.8rem, 1.3rem + 2vw, 4.5rem);
                        }
                        @include media-breakpoint-down(xl) {
                            font-size: 2.39rem;
                            line-height: 2.8rem;
                            @include generate-spacing(margin, 35, 'b');
                        }
                    }
                    .carousel-text {
                        @include large();
                        color: $corporate-white;
                        p {
                            @include generate-spacing(margin, 0, 'b');
                        }
                        @include generate-spacing(margin, 45, 'b');
                        @include media-breakpoint-up(xl) {
                            font-size: clamp(1.1rem, 0.624rem + 0.635vw, 1.64rem);
                            line-height: clamp(1.6rem, 0.806rem + 1.059vw, 2.5rem);
                        }
                        @include media-breakpoint-down(xl) {
                            font-size: 1.1rem;
                            line-height: 1.6rem;
                            @include generate-spacing(margin, 40, 'b');
                        }
                    }
                    .carousel-image {
                        z-index: calc(var(--base-z-index) + 1);
                        @include generate-spacing(margin, 45, 'b');
                        img {
                            width: auto;
                            max-height: 100px;
                            @include media-breakpoint-up(md) {
                                max-width: 500px;
                            }
                            @include media-breakpoint-down(md) {
                                max-width: 340px;
                                max-height:60px;
                            }
                        }
                    }
                }
            }
        }

        &.hero-Compact {
            .glide__bullets.primary__bullets {
                padding-left: 2.3rem;
                padding-right: 2.3rem;
            }
            .glide__track {
                .glide__slides {
                    .glide__slide {
                        border-radius: 20px;
                        border: solid 1px rgb(26,26,27);
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                .carouselslide {
                    .carousel-slide {
                        .container-lg {
                            h2.carousel-title {
                                font-size: 2rem;
                                line-height: 2.8rem;
                                font-weight: 400;
                                text-wrap-style: pretty;
                            }
                            .carousel-text {
                                text-wrap-style: pretty;   
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                .glide__track {
                    .glide__slides {
                        .glide__slide {
                            .carouselslide {
                                .carousel-slide {
                                    .container-lg {
                                        padding-left: 2.3rem;
                                        padding-right: 2.3rem;

                                        /* replace hard coded div.col-lg-6 with div.col-lg-4 styles */
                                        .aem-Grid.row > div.col-lg-6 { 
                                            flex: 0 0 auto; /* Ensures the element respects the overridden width */ 
                                            width: 33.333333%; /* Matches Bootstrap's col-lg-4 */ 
                                            max-width: 33.333333%; /* Prevents it from exceeding the intended width */ 
                                        }  

                                        h2.carousel-title {
                                            font-size: 2rem;
                                            line-height: 2.5rem;
                                            font-weight: 400;
                                            text-wrap-style: pretty;
                                            margin-bottom: clamp(0rem, -1.667rem + 2.222vw, 1rem);
                                        }

                                        .carousel-text {
                                            font-size: 1rem;
                                            line-height: 1.5rem;
                                            font-weight: 400;
                                            text-wrap-style: pretty;   
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } 
    }
}
