.amd-bs5 {
	.embed {
		.cmp-embed {
			@include generate-spacing(padding, 45, 'b');
		}
		&.cmp-embed__no-bottom-padding{
			.cmp-embed {
				@include generate-spacing(padding, 0, 'b');
			}
		}
	}
}