.amd-bs5 {
	pre[class*="language-"]:before,
	pre[class*="language-"]:after {
	    display: none;
	}	
	code {
    	background-color: #f2f2f2;
	}
	pre>code {
		background-color: #272822;
	}
}
