body {
  @include body();
}

// styles for modals that are moved by js to the root in the dom
.modal {
  @include modal;
}

.container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  @include generate-spacing(padding, 45, 'y');

  // Horizontal container padding to override bootstrap non-responsive padding.
  @include media-breakpoint-up(xs) {
    @include generate-spacing(padding, 45, 'x');
  }
  @include media-breakpoint-up(lg) {
    @include generate-spacing(padding, 70, 'x');
  }
}

.container-fluid {
  @include generate-spacing(padding, 45, 'y');
}

.amd-bs5 {
  color: $font-dark;

  // Paragraph and list margins
  p, dl, ol, ul {
    @include generate-spacing(margin, 45, 'b');
  }

  // Reduce margin on lists
  p + ul, p + ol {
    @include generate-spacing(margin, 40, 't', -1);
  }

  // No margin on nested lists
  li {
    ol, ul {
      @include generate-spacing(margin, 0, 'b');
    }
  }

  // Headlines
  h1 {
    @include h1-medium();
  }

  h2 {
    @include h2-medium();
  }

  h3 {
    @include h3-medium();
  }

  h4 {
    @include h4-medium();
  }

  h5 {
    @include h5-medium();
  }

  h6 {
    @include h6-medium();
  }

  // Link styles
  a {
    color: $access-link-dark;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.disableHover {
      &:hover, &:focus, &.visited {
        text-decoration: none;
      }
    }
  }

  .text-light {
    color: $font-light;

    a {
      color: $access-link-light;
    }
  }

  .text-grey {
    color: $neutral-50;

    a {
      color: $access-link-light;
    }
  }
  
  #skipToMain {
    color: var(--main-link-color, #{$access-link-dark});
  }
  // Background color
  &.background-gray {
    background-color: $gray;
    --main-link-color: #{$access-link-dark};
  }

  &.background-black {
    background-color: $corporate-black;
    --main-link-color: #{$access-link-light};
  }

  &.background-white {
    background-color: $corporate-white;
    --main-link-color: #{$access-link-dark};
  }

  //Cursor Animation
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  p, h1, h2, h3, h4, h5, h6, section {
    span.cursor {
      animation: blink 1s infinite;
      @include generate-spacing(padding, 10, 's');
      @include generate-spacing(padding, 30, 'e');
    }

    span.icon-check-circle {
      @include expressive-icons;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.5 0C4.32776 0 0 4.32777 0 9.5C0 14.6722 4.32776 19 9.5 19C14.6722 19 19 14.7778 19 9.5C19 4.22223 14.7778 0 9.5 0ZM8.5 12.7071L13.8536 7.35356L13.1465 6.64645L8.5 11.2929L6.35364 9.14645L5.64648 9.85356L8.5 12.7071Z' fill='%23007C97'/%3E%3C/svg%3E");
      background-position: top 0.1875rem left;
    }

    span.icon-x-circle {
      @include expressive-icons;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 9C0 4.10001 4.09998 0 9 0C14 0 18 4 18 9C18 14 13.9 18 9 18C4.09998 18 0 13.9 0 9ZM9 8.29288L11.6465 5.64645L12.3536 6.35356L9.70715 9L12.3536 11.6465L11.6465 12.3536L9 9.70714L6.35364 12.3536L5.64648 11.6465L8.29297 9L5.64648 6.35356L6.35364 5.64645L9 8.29288Z' fill='%23007C97'/%3E%3C/svg%3E");
      background-position: top 0.1875rem left;
    }

    &.background-black {
      background-color: $corporate-black;
    }

    &.background-white {
      background-color: $corporate-white;
    }

    //Cursor Animation
    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    p, h1, h2, h3, h4, h5, h6, section {
      span.cursor {
        animation: blink 1s infinite;
      }

      span.icon-check-circle {
        @include expressive-icons;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.5 0C4.32776 0 0 4.32777 0 9.5C0 14.6722 4.32776 19 9.5 19C14.6722 19 19 14.7778 19 9.5C19 4.22223 14.7778 0 9.5 0ZM8.5 12.7071L13.8536 7.35356L13.1465 6.64645L8.5 11.2929L6.35364 9.14645L5.64648 9.85356L8.5 12.7071Z' fill='%23007C97'/%3E%3C/svg%3E");
        background-position: top 0.1875rem left;
      }

      span.icon-x-circle {
        @include expressive-icons;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 9C0 4.10001 4.09998 0 9 0C14 0 18 4 18 9C18 14 13.9 18 9 18C4.09998 18 0 13.9 0 9ZM9 8.29288L11.6465 5.64645L12.3536 6.35356L9.70715 9L12.3536 11.6465L11.6465 12.3536L9 9.70714L6.35364 12.3536L5.64648 11.6465L8.29297 9L5.64648 6.35356L6.35364 5.64645L9 8.29288Z' fill='%23007C97'/%3E%3C/svg%3E");
        background-position: top 0.1875rem left;
      }

      span.icon-time {
        @include expressive-icons;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9 0C4.09998 0 0 4.10001 0 9C0 13.9 4.09998 18 9 18C13.9 18 18 14 18 9C18 4 14 0 9 0ZM9 16C12.866 16 16 12.866 16 9C16 5.134 12.866 2 9 2C5.13403 2 2 5.134 2 9C2 12.866 5.13403 16 9 16Z' fill='%23007C97'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.12133 3V9L5 13.2427C6.05474 14.3284 7.51184 15 9.12131 15C12.3403 15 14.9497 12.3137 14.9497 9C14.9497 5.6863 12.3403 3.00001 9.12133 3Z' fill='%23007C97'/%3E%3C/svg%3E");
        background-position: center left;
      }

      span.icon-map {
        @include expressive-icons;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='14' height='19' viewBox='0 0 14 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.86401 18.5731C9.73438 16.1845 14 10.3953 14 7.14349C14 3.1982 10.8646 0 7 0C3.13391 0 0 3.1982 0 7.14349C0 10.3953 4.23279 16.1845 6.13599 18.5731C6.58435 19.1423 7.41565 19.1423 7.86401 18.5731ZM7 8C8.10461 8 9 7.10457 9 6C9 4.89543 8.10461 4 7 4C5.89539 4 5 4.89543 5 6C5 7.10457 5.89539 8 7 8Z' fill='%23007C97'/%3E%3C/svg%3E");
        background-position: center left;
      }

      span.icon-calendar {
        @include expressive-icons;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.3125 0V2.125H9.5625V0H11.6875V2.125H14.875V4.3125H0V2.125H3.1875V0H5.3125ZM0 5.375H14.875V16H0V5.375ZM2 7H13V14H2V7ZM6 8H3V11H6V8Z' fill='%23007C97'/%3E%3C/svg%3E");
        background-position: center left;
      }
    }

    // Youtube modal styles
    #youtube-modal, #brightcove-modal {
      .btn-close {
        @include modal-closeIcon;
      }
    }

    span.icon-time {
      @include expressive-icons;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9 0C4.09998 0 0 4.10001 0 9C0 13.9 4.09998 18 9 18C13.9 18 18 14 18 9C18 4 14 0 9 0ZM9 16C12.866 16 16 12.866 16 9C16 5.134 12.866 2 9 2C5.13403 2 2 5.134 2 9C2 12.866 5.13403 16 9 16Z' fill='%23007C97'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.12133 3V9L5 13.2427C6.05474 14.3284 7.51184 15 9.12131 15C12.3403 15 14.9497 12.3137 14.9497 9C14.9497 5.6863 12.3403 3.00001 9.12133 3Z' fill='%23007C97'/%3E%3C/svg%3E");
      background-position: center left;
    }

    span.icon-map {
      @include expressive-icons;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='14' height='19' viewBox='0 0 14 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.86401 18.5731C9.73438 16.1845 14 10.3953 14 7.14349C14 3.1982 10.8646 0 7 0C3.13391 0 0 3.1982 0 7.14349C0 10.3953 4.23279 16.1845 6.13599 18.5731C6.58435 19.1423 7.41565 19.1423 7.86401 18.5731ZM7 8C8.10461 8 9 7.10457 9 6C9 4.89543 8.10461 4 7 4C5.89539 4 5 4.89543 5 6C5 7.10457 5.89539 8 7 8Z' fill='%23007C97'/%3E%3C/svg%3E");
      background-position: center left;
    }

    span.icon-calendar {
      @include expressive-icons;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.3125 0V2.125H9.5625V0H11.6875V2.125H14.875V4.3125H0V2.125H3.1875V0H5.3125ZM0 5.375H14.875V16H0V5.375ZM2 7H13V14H2V7ZM6 8H3V11H6V8Z' fill='%23007C97'/%3E%3C/svg%3E");
      background-position: center left;
    }
  }

  #youtube-modal, #brightcove-modal {
    .modal-content {
      border: none;
    }
    .modal-body {
      padding: 0;
    }
    .btn-close {
      @include modal-closeIcon;
    }
  }

  .shadow-xl {
    box-shadow: $box-shadow-xl;
  }

  // Default arrow distance arrow from top.
  body {
    --glide-arrows-top: 32%;
  }
  
  .sticky-top, .sticky-sm-top, .sticky-md-top, .sticky-lg-top, .sticky-xl-top {
	top : map-get($spacers, 70);
  }
}

// Rule to show all IDs of elements with IDs (bookmarklet must be run first)
[data-show-authoring-id]::before {
  content: attr(data-show-authoring-id);
  position: absolute;
  background-color: white;
  color: black;
  opacity: .6;
  /* left: 50%; */
  border: 1px solid red;
  font-size: 8px;
  padding: 10px;
  z-index: 5000;
}
[data-show-authoring-id]:hover::before {
  opacity: 1;
  font-size: 12px;
  padding: 10px;
  z-index: 5000;
}

.icon-x {
	display: inline-block;
	width: .6em;
	height: .6em;
	background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 178 177" fill="none"%3E%3Cg clip-path="url(%23clip0_472_180)"%3E%3Cpath d="M40.1052 88.9906L0.00978286 48.8952L0.00976562 0.000183105L89.0002 88.9907L-0.00931981 178L-0.00933705 129.105L40.1052 88.9906Z" fill="%23000000"/%3E%3Cpath d="M138.04 89.0096L178.136 129.105L178.136 178L89.1453 89.0095L178.155 -2.12702e-05L178.155 48.895L138.04 89.0096Z" fill="%23000000"/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_472_180"%3E%3Crect width="1em" height="1em" fill="%23000000"/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	vertical-align: middle;
  margin-bottom: 3px;
}

h5 .icon-x, h6 .icon-x { margin-bottom: 2px;}
p .icon-x { margin-bottom: 0;}
.carousel-title .icon-x, 
.carousel-text .icon-x,
.text-light .icon-x  { background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 178 177" fill="none"%3E%3Cg clip-path="url(%23clip0_472_180)"%3E%3Cpath d="M40.1052 88.9906L0.00978286 48.8952L0.00976562 0.000183105L89.0002 88.9907L-0.00931981 178L-0.00933705 129.105L40.1052 88.9906Z" fill="%23ffffff"/%3E%3Cpath d="M138.04 89.0096L178.136 129.105L178.136 178L89.1453 89.0095L178.155 -2.12702e-05L178.155 48.895L138.04 89.0096Z" fill="%23ffffff"/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_472_180"%3E%3Crect width="1em" height="1em" fill="%23ffffff"/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E');}
.text-Teal .icon-x  { background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 178 177" fill="none"%3E%3Cg clip-path="url(%23clip0_472_180)"%3E%3Cpath d="M40.1052 88.9906L0.00978286 48.8952L0.00976562 0.000183105L89.0002 88.9907L-0.00931981 178L-0.00933705 129.105L40.1052 88.9906Z" fill="%2300c2de"/%3E%3Cpath d="M138.04 89.0096L178.136 129.105L178.136 178L89.1453 89.0095L178.155 -2.12702e-05L178.155 48.895L138.04 89.0096Z" fill="%2300c2de"/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_472_180"%3E%3Crect width="1em" height="1em" fill="%2300c2de"/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E'); } 
.text-Teal-dark .icon-x { background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 178 177" fill="none"%3E%3Cg clip-path="url(%23clip0_472_180)"%3E%3Cpath d="M40.1052 88.9906L0.00978286 48.8952L0.00976562 0.000183105L89.0002 88.9907L-0.00931981 178L-0.00933705 129.105L40.1052 88.9906Z" fill="%23008094"/%3E%3Cpath d="M138.04 89.0096L178.136 129.105L178.136 178L89.1453 89.0095L178.155 -2.12702e-05L178.155 48.895L138.04 89.0096Z" fill="%23008094"/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_472_180"%3E%3Crect width="1em" height="1em" fill="%23008094"/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E');}
.text-Orange .icon-x  { background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 178 177" fill="none"%3E%3Cg clip-path="url(%23clip0_472_180)"%3E%3Cpath d="M40.1052 88.9906L0.00978286 48.8952L0.00976562 0.000183105L89.0002 88.9907L-0.00931981 178L-0.00933705 129.105L40.1052 88.9906Z" fill="%23f26522"/%3E%3Cpath d="M138.04 89.0096L178.136 129.105L178.136 178L89.1453 89.0095L178.155 -2.12702e-05L178.155 48.895L138.04 89.0096Z" fill="%23f26522"/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_472_180"%3E%3Crect width="1em" height="1em" fill="%23f26522"/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E'); } 
.text-Red .icon-x { background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 178 177" fill="none"%3E%3Cg clip-path="url(%23clip0_472_180)"%3E%3Cpath d="M40.1052 88.9906L0.00978286 48.8952L0.00976562 0.000183105L89.0002 88.9907L-0.00931981 178L-0.00933705 129.105L40.1052 88.9906Z" fill="%23ed1c24"/%3E%3Cpath d="M138.04 89.0096L178.136 129.105L178.136 178L89.1453 89.0095L178.155 -2.12702e-05L178.155 48.895L138.04 89.0096Z" fill="%23ed1c24"/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_472_180"%3E%3Crect width="1em" height="1em" fill="%23ed1c24"/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E'); } 
.text-contrastGrey .icon-x { background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 178 177" fill="none"%3E%3Cg clip-path="url(%23clip0_472_180)"%3E%3Cpath d="M40.1052 88.9906L0.00978286 48.8952L0.00976562 0.000183105L89.0002 88.9907L-0.00931981 178L-0.00933705 129.105L40.1052 88.9906Z" fill="%23747579"/%3E%3Cpath d="M138.04 89.0096L178.136 129.105L178.136 178L89.1453 89.0095L178.155 -2.12702e-05L178.155 48.895L138.04 89.0096Z" fill="%23747579"/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_472_180"%3E%3Crect width="1em" height="1em" fill="%23747579"/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E');}  
.text-gold .icon-x  { background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 178 177" fill="none"%3E%3Cg clip-path="url(%23clip0_472_180)"%3E%3Cpath d="M40.1052 88.9906L0.00978286 48.8952L0.00976562 0.000183105L89.0002 88.9907L-0.00931981 178L-0.00933705 129.105L40.1052 88.9906Z" fill="%23c1a968"/%3E%3Cpath d="M138.04 89.0096L178.136 129.105L178.136 178L89.1453 89.0095L178.155 -2.12702e-05L178.155 48.895L138.04 89.0096Z" fill="%23c1a968"/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_472_180"%3E%3Crect width="1em" height="1em" fill="%23c1a968"/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E'); }

#skipToMain {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  &:focus {
    position: static;
    left: initial;
    width: auto;
    height: auto;
    overflow: visible;
  }
}