.amd-bs5 {
	.columns {
		&.amd-flex-align-stretch {
			&>.row>div>.container-parsys {
				height: 100%;
				&>.cmp-container {
					height: 100%;
					&>.cmp-container__content {
						height: 100%;
						&>.aem-Grid, &>.container-fluid>.aem-Grid, &>.container-lg>.aem-Grid {
							display:flex;
				  			flex-direction: column;
				  			height: 100%;
							.text {
								flex-grow: 1;
							}
				  			&>div:nth-child(2) {
								flex-grow: 1;
							}
							&>.teaser {
								flex-grow: 2;
								&>.cmp-teaser {
									&>.cmp-teaser__content {
									    height: 100%;
									    display: flex;
									    flex-direction: column;
									    &>*:nth-child(2) {
										    flex-grow: 1;
										}
									}
								}
							}
							&>.button {
								display: flex;
					  			align-items: flex-end;
							}
						}
					}
				}
			}
		}
		&.amd-flex-align-stretch-bg {
			&>.row>div>.container-parsys {
				height: 100%;
				&>.cmp-container {
					height: 100%;
					&>.cmp-container__content {
						height: 100%;
					}
				}	
			}
		}
		&.amd-flex-align-middle {
			&>.row>div {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
		&.amd-flex-align-bottom {
			&>.row>div {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
			}
		}
		&.amd-flex-justify-center {
			&>.row {
				justify-content: center;
			}
		}
		&.amd-flex-justify-right {
			&>.row {
				justify-content: right;
			}
		}
		&.amd-vertical-border-dark {
			&>.row>div:not(:first-child) {
				@include media-breakpoint-up(md) {
					border-left: 1px solid $neutral-60;
				}
			}
		}
		&.amd-vertical-border-light {
			&>.row>div:not(:first-child) {
				@include media-breakpoint-up(md) {
					border-left: 1px solid $neutral-20;
				}
			}
		}
		&.amd-row-gap {
			.row {
				row-gap: map-get($gutters, 45);
			}
		}
	}
}