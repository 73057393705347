.amd-bs5 {
    header {
		&.common-header {
			.icon-nav {
				background-color: $corporate-black;
				order: 4;
				@include media-breakpoint-up(xl) {
					padding-right: 1.625rem;
				}
				.header-icons {
					.icon-item {								
						@include media-breakpoint-up(sm) {
							margin-left: map-get($spacers, 45);
						} 
						
						i {
							display: inline-block;
				            width: 2.6rem;
				            @include font-awesome();
						}
						&>a, > a, a#shoppingCart {
							@include large();
							font-size: map-get($spacers, 40);
							color: $font-light;
							@include generate-spacing(padding, 35, 'y');
							&:hover {
								background-color: $neutral-80;
								border: none;
								border-radius: 5px;
							}
							
						}
						&.search-dropdown {
							ul.dropdown-menu {
								transform: none;
								margin: 0;
								min-width: 90vw;
								@include media-breakpoint-up(sm) {
									min-width: 29.625rem;
								}
								li div.dropdown-pointer {
								  margin-left:auto;
								  margin-right:10px;
								}
								.dropdown-menu-content {
									padding: 0;
									.header-searchbox {
										padding: 0;
										.dropdown-item {
											padding: 0;
											&:hover {
												background-color: inherit;
											}
											atomic-search-box::part(wrapper) {
												border: none;
												background-color: $neutral-60;
												flex-direction: row-reverse;
												&:focus-within {
													box-shadow: none;
												}
											}
											
											atomic-search-box::part(input) {
												--atomic-neutral-dark: #{$corporate-white};
											}
											
											atomic-search-box::part(submit-button) {
												background-color: transparent;
												&:focus-visible {
													box-shadow: none;
													outline: none;
													margin: 0;
												}
											}
											
											atomic-search-box::part(clear-button) {
												background-color: transparent;
												color: $corporate-white;
											}
											
										}
									}
								}
							}
						}
						.shopping-cart-dropdown .dropdown-menu {
							padding: 0;
							margin: 0;
							border: 0;
							height: 100%;
							top: 0;
							width: 100%;
							position: fixed;
							z-index: 3001;
							@include media-breakpoint-up(lg) {
								width: 550px;
							}
						}
					}
				}	
			}
		}
	}
}