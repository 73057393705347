.noscroll{
	@include media-breakpoint-down(xl) {
		margin: 0; 
		height: 100%; 
		overflow: hidden;
	}
}
.amd-bs5 {
    header {
		&.common-header {
			.navbar {
				background-color: $corporate-black;
				padding: 0;
				color: $header-font;
				padding: map-get($spacers, 35);
				box-shadow: $skim;
				@include media-breakpoint-up(xl) {
					padding: map-get($spacers, 45) map-get($spacers, 55);
				}
				@include media-breakpoint-between(sm, xl) {
					padding: map-get($spacers, 45);
				}
				
				>.main-nav {		
					padding: 0;
					a {
	                    color: inherit;
	                    //disable the dropdown arrow
						&.dropdown-toggle {
							&:after {
								display: none;	
							}
						}
	                }
	                
	                //Logo			
					.header-logo {
						order: 2;
						padding: 0 0 0 map-get($spacers, 35);
						@include media-breakpoint-up(xl) {
							padding: 0 map-get($spacers, 60) 0 map-get($spacers, 45);
						}
						@include media-breakpoint-between(sm, xl) {
							padding: 0 0 0 map-get($spacers, 45);
						}
						a.navbar-brand {
							padding: 0;
							margin: 0;
						}
						img {
							width: 5.3rem;
							height: calc(map-get($spacers, 40) + map-get($spacers, 35) + map-get($spacers, 35));
							@include media-breakpoint-up(xl) {
								width: map-get($spacers, 75);
								height: map-get($spacers, 50);
							}
							@include media-breakpoint-between(sm, xl) {
								width: map-get($spacers, 70);
								height: map-get($spacers, 50);
							}							
						}					
					}
					
					// Mobile + Tablet hamburger icon
					button.navbar-toggler {
						background-color: $corporate-black;
						color: $corporate-white;
						order: 1;
						margin: map-get($spacers, 35);
						border: none;
						padding: 0;
						&:focus {
							box-shadow: none;
						}
						&#nav-icon {
		                    width: 1.4rem;
		                    height: map-get($spacers, 40);
		                    position: relative;
		                    transform: rotate(0deg);
		                    transition: .5s ease-in-out;
		                    span {
								display: block;
								position: absolute;
								height: 2px;
								width: 100%;
								background: $corporate-white;
								opacity: 1;
								left: 0;
								transform: rotate(0deg);
								transition: .25s ease-in-out;
								&:nth-child(1) {
									transform: rotate(45deg);
									top: 8px;
									left: 0px;
								}
								&:nth-child(2) {
									width: 0%;
									opacity: 0;
								}
								&:nth-child(3) {
									transform: rotate(-45deg);
									top: 8px;
									left: 0px;
								}
							}
							&.collapsed {
								span {
									&:nth-child(1) {
										top: 0;
										transform: none;
										left: 0;
										transform-origin: left center;
									}
									&:nth-child(2) {
										top: 8px;
										width: 100%;
										opacity: 1;
										transform-origin: left center;
									}
									&:nth-child(3) {
										top: 16px;
										transform: none;
										left: 0;
										transform-origin: left center;
									}
								}
							}
		                }
						
					}
	                
	                //Submenu + Top Level Container
					.navbar-nav-container {					
						order: 3;
						width: 100%;
						background-color: $neutral-90;
						position: fixed;
						overflow-y: scroll;
						overflow-x: hidden;
						top: 56px;
						right: 0;
						z-index: 3000;
						height: calc(100% - 56px);	
						box-shadow: $raised;		
						@include media-breakpoint-up(xl) {
							background-color: unset;
							position: inherit;
							top: unset;
							right: unset;
							overflow: hidden;
							height: auto;
						}
						@include media-breakpoint-between(sm, xl) {
							top: 88px;
							width: 60%;
							left: 0;
							height: calc(100% - 88px);	
						} 					
						ul.navbar-nav {
							margin-bottom: 0;
							
							//Make the Dropdown full width for Desktop
							&.nav-mega {
							  width: 100%;
							  .dropdown {
								  position: static;
								}
								.dropdown-menu.mega-menu { 
									
									background: $corporate-white;
									border: none;
									padding: 0;
									margin: 0;
									@include media-breakpoint-up(xl) {
										background: transparent;
										margin: 0 calc(map-get($spacers, 45) + map-get($spacers, 55));
										width: calc(100% - 2*(map-get($spacers, 45) + map-get($spacers, 55)));										
									}
									@include media-breakpoint-down(xl) {								
										.cmp-headermenu__tabpanel {
											&.expanded {
												.cmp-headermenu__accordionbody {
													display: block;
												}
											}											
											&.collapsed {
												.cmp-headermenu__accordionbody {
													display: none;
												}
											}
										}
									}								
								}
							}
							
							li {
								a, button {
									padding: 0;						
									text-decoration: none;
									
									//Generalized class for rendering of links and buttons for accordion and tabs title
									&.header-menu-links {
										padding: map-get($spacers, 40) map-get($spacers, 45);
										border-radius: 0;
										color: $corporate-white;
										font-size: map-get($spacers, 40);
										line-height: 1.5rem;
										font-weight: 500;
										border-bottom: 1px solid $neutral-80;
										text-align: left;
										display: flex;
										justify-content: space-between;
										align-items: center;
										@include media-breakpoint-down(xl) {
											.chevron-up-down-icon {
												@include font-awesome();
							    				font: var(--fa-font-sharp-solid);
									            float: right;
									            &:before {
									                content: "\f078";
									            }
											}
											&.show {
												.chevron-up-down-icon {
													&:before {
										                content: "\f077";
										            }
												}
											}
										}
									}
									
									//Top level Navigation Links
									&.top-level {										
										padding: map-get($spacers, 40) map-get($spacers, 45);
										margin: 0;
										@include media-breakpoint-up(xl) {											
											flex-direction: column;
											margin: 0 map-get($spacers, 60) 0 0;
											border: none;
											padding: 0;
											//border-bottom: 2px solid transparent;
											font-size: inherit;
											line-height: inherit;
											font-weight: inherit;
											text-align: inherit;
											box-shadow: none;
											&:after {
												display: block;
												content: '';
												border: 0;
												border-bottom: 2px solid $corporate-white;
												transform: scaleX(0);
												transition: transform 250ms ease-in-out;
												padding-top: map-get($spacers, 25);
												margin: 0;
												width: 100%;
											}
											&:hover, &.show, &:focus {
												border-top: 0;
												border-left: 0;
												border-right: 0;
												outline: none;
											}
											&:hover, &.show, &:focus {
												&:after {
													transform: scaleX(1);
													border-top: 0;
													border-left: 0;
													border-right: 0;
													outline: none;
													
												}
											}
											
										}	
									}				
									
								}
							}
						}
					}
				}
			}
		}
	}
}
