.amd-bs5 {
    .accordion {
        background-color: inherit;
        color: inherit;
        position: relative;
        .accordion-item,
        .accordion-button {
            background-color: $corporate-white;
            color: inherit;
            border-style: solid;
            box-shadow: none;
            border-color: #e1e1e2;
        }
        .accordion-item {
            //these button styles reverse normal button styles to aid Authors with Copy/Paste
            .btn-Primary-White {
                --primary: var(--foreground);
                --secondary: var(--background);
            }
            .btn-Secondary-White {
                --secondary: var(--foreground);
            }
            .btn-Secondary-Accent-Light a {
                color: var(--accessible-light);
                &:hover,
                &:focus {
                    color: var(--accessible-light);
                }
            }
            .text, .title {
                h2 {
                    font-size: $h2-font-size;
                    font-weight: $font-weight-bold;
                    @include h2-medium;
                }
                h3 {
                    font-size: $h3-font-size;
                    font-weight: $font-weight-bold;
                    @include h3-medium;
                }
                h4 {
                    font-size: $h4-font-size;
                    font-weight: $font-weight-bold;
                    @include h4-medium;
                }
                h5 {
                    font-size: $h5-font-size;
                    font-weight: $font-weight-bold;
                    @include h5-medium;
                }
                h6 {
                    font-size: $h6-font-size;
                    font-weight: $font-weight-bold;
                    @include h6-medium;
                }
            }
            .title {
                &.expressive {
                    h2.cmp-title__text, h3.cmp-title__text {
                        @include h2-expressive;
                    }
                }
                &.expressiveReg {
                    h2.cmp-title__text, h3.cmp-title__text {
                        @include h2-expressiveReg;
                    }
                }
            }
        }

        .accordion-button {
            @include open-sans;
            font-weight: 600;
            &:hover {
                background-color: $neutral-20;
                text-decoration: underline;
            }
            &:not(.collapsed) {
                color: inherit;
                border-bottom: 1px solid #e1e1e2;
                box-shadow: none;
            }
            &:after {
                @include carrot-dark;
            }
            &:focus {
                box-shadow: none;
            }
        }
        &.accordion-bg-dark {
            --foreground: var(--c-white);
            --background: var(--c-black);
            --accessible-light: #{$access-link-light};
            --accessible-dark: #{$access-link-light};
            .accordion {
                .accordion-item {
                    background-color: $neutral-80;
                    border: 1px;
                }
                .accordion-button {
                    background-color: $neutral-80;
                    &:hover {
                        background-color: $neutral-60;
                    }
                    &:not(.collapsed) {
                        color: $corporate-white;
                        box-shadow: none;
                    }
                    &:after {
                        @include carrot-light;
                    }
                }
                .accordion-collapse {
                    background-color: $neutral-90;
                }
            }
        }
        &.bg-transparent {
            .accordion {
                .accordion-item:first-child {
                    .accordion-button {
                        padding-top: 0;
                    }
                }
                .accordion-item:last-of-type {
					.collapsed {
						border-bottom: 1px solid #e1e1e2;
						border-top: 0;
					}
				}
                .accordion-item {
                    background-color: transparent;
                    border: 0;
                    .accordion-button {
                        border-bottom: 1px solid #e1e1e2;
                        padding-left: 0;
                        padding-right: 0;
                        background-color: transparent;
                        &:not(.collapsed) {
                            color: inherit;
                            box-shadow: none;
                        }
                    }
                }
			}
        }
        &.text-light {
            --foreground: var(--c-white);
            --background: var(--c-black);
            --accessible-light: #{$access-link-light};
            --accessible-dark: #{$access-link-light};
            .accordion-button {
                border-bottom: 1px solid #636466;
                &:after {
                    @include carrot-light;
                }
                &:focus {
            		border-color: #636466;
                }
                &:not(.collapsed) {
                    box-shadow: none;
                }
            }
        }
        &.amd-accordion-flush {
            @extend .accordion-flush;
        }
        @include body();
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 0;
            @include button();
        }
        &.accordion-float {
            position: absolute;
            width: 100%;
        }
    }
}