.amd-bs5 {
  .locations {
    .col {
      @include generate-spacing(margin, 45, 'b');
    }
    .card.location-card {
      box-shadow: none;
      border-radius: 0;
      height: 100%;
      @include medium;
      margin-bottom: 0;
      &:hover {
        box-shadow: none;
      }
      p {
        margin-bottom: map-get($spacers, 35);
      }
    }
  }
}