.amd-bs5 {
    header.common-header .icon-nav .header-icons .icon-item , footer.common-footer .social-menu {
		ul.dropdown-menu {
			background-color: transparent;
			box-shadow: $skim;
			border: none;
			border-radius: 0;
			padding-bottom: 0;
			transform: translateX(40%);
			z-index: 2999;
			li div.dropdown-pointer {
			  width: 0; 
			  height: 0; 
			  border-left: map-get($spacers, 35) solid transparent;
			  border-right: map-get($spacers, 35) solid transparent;
			  border-bottom: map-get($spacers, 35) solid $neutral-90;
			  margin:auto;
			}					
			li ul.dropdown-menu-content {
				background-color: rgba(26, 26, 27, 0.63);
				border-radius: 5px;
				backdrop-filter: blur(30px);
				color: $corporate-white;							
				padding: 0 map-get($spacers, 35);
				list-style-type: none;
				margin: 0;
				li {
					padding: map-get($spacers, 35) 0;
					border-bottom: 1px solid rgba(71, 73, 78, 0.53);				
					&:last-child {
						border-bottom: none;
					}
					a {
						&:hover {
							background-color: transparent;
						}
						&:active, &:focus {
							color: inherit;
						    background-color: inherit;
						    outline: none; 
						}
					}
				}							
			}
		}				
	}
}




