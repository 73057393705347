.amd-bs5 {
  .comparisonChart {
    .cmp-comparison-chart {
      .comparison-chart {
        --scale-border: 1px dashed rgb(115,115,116, 50%);
        --vertical-bar-width: 150px;
        --vertical-bar-gap: 1em;
        position: relative;
        @include generate-spacing(margin, 35, 'b');
        .chart-wrapper {
          display: flex;
          flex-direction: column;
          position: relative;
          &.legend-above {
            @include generate-spacing(margin, 40, 't');
            @include generate-spacing(margin, 50, 'b');
          }
          &.legend-below {
            @include generate-spacing(margin, 45, 't');
            @include generate-spacing(margin, 30, 'b');
            .legend {
              order: 1;
              @include generate-spacing(margin, 55, 't');
            }
          }
          &.legend-center {
			.legend {
				justify-content: center;
			}
          }
          &.legend-right {
			.legend {
				justify-content: right;
			}
          }
          .legend {
            @include generate-spacing(margin, 35, 'x');
            @include generate-spacing(margin, 35, 'y');
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            .legend-item {
              display: flex;
              flex-wrap: wrap;
              .legend-color {
                height: 25px;
                width: 25px;
                @include generate-spacing(margin, 30, 'e');
              }
              .legend-text {
                @include generate-spacing(padding, 40, 'e');
                @include medium;
                p {
                  @include generate-spacing(margin, 0, 'b');
                }
              }
            }
          }
          .chart-container {
            position: relative;
            .chart-group {
              .chart-group-label {
                font-size: $font-size-base;
                font-weight: bold;
                @include generate-spacing(padding, 35, 's');
                span {
                  vertical-align: super
                }
              }
              .chart-item {
                overflow: hidden;
                z-index: 2;
                position: relative;
                .chart-item-wrapper {
					display: flex;
					flex-direction: column;
	                .bar-label {
	                  @include generate-spacing(padding, 35, 't');
	                  @include generate-spacing(padding, 35, 's');
	                  font-size: $font-size-base;
	                }
	                .score {
	                  @include generate-spacing(padding, 35, 'e');
	                  @include generate-spacing(margin, 10, 't');
	                  @include klavika();
	                  height: 35px;
	                  font-size: 1.27rem;
	                  font-weight: 400;
	                  line-height: 36px;
	                  text-align: right;
	                  color: white;
	                  transition: 3s;
	                  &.bar-text-dark {
	                    color: $corporate-black;
	                  }
	                }
				 }
              }
              @include media-breakpoint-up(sm) {
	              &.label-left {
	                .chart-item-wrapper {
						flex-direction: row;
						align-items: center;
						.bar-label {
							min-width: 20%;
							max-width: 20%;
							text-align: right;
							padding-left: 0;
							padding-top: 0;
						}
						.score-wrapper {
							width: 80%;
							overflow: hidden;
							@include generate-spacing(margin, 35, 's');
						}
					}
					.scale {
						@include generate-spacing(padding, 35, 's');
						padding-left: calc(20% + map-get($spacers, 35));
					}
				    .chart-group-label {
					  text-align: right;
					  min-width: 20%;
					  max-width: 20%;
					  padding-left: 0;
				    }
					&.single-bar {
						display: flex;
						align-items: center;
						.chart-group-label {
							display: flex;
							align-items: center;
							text-align: right;
							justify-content: flex-end;
						}
						.chart-item {
							width: 100%;
							@include generate-spacing(margin, 35, 's');
							.score-wrapper {
								width: 100%;
								margin-left: 0;
							}
						}
					}
				  }
			  }
              .scale {
                width: 100%;
                position: absolute;
                height: 100%;
                bottom: 0;
                display: block;
                z-index: 1;
                div {
                  height: 100%;
                  border-right: var(--scale-border);
                  float: left;
                  position: relative;
                  span {
                    position: absolute;
                    bottom: -22px;
                    @include tiny();
                    right: 0;
                    transform: translateX(50%);
                  }
                  &:first-child {
                    span {
                      min-width: 30px;
                      left: -12px;
                    }
                  }
                  &:last-child {
                    display: none;
                  }
                }
              }
            }
          }
          &.chart-vertical {
            .chart-container {
              display: flex;
              justify-content: center;
              .chart-group {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: end;
                margin: 0;
                @include generate-spacing(margin, 35, 'x');
                @include generate-spacing(margin, 35, 'y');
                height: 425px;
                width: 90%;
                .bar-group {
                  display: flex;
                  position: relative;
                  height: 100%;
                  width: 100%;
                  @include generate-spacing(padding, 40, 's');
                  .chart-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: var(--vertical-bar-gap);
                    height: 100%;
                    width: 100%;
                    justify-content: center;
                    .chart-item-wrapper {
                      flex: 1 1 0;
                      flex-direction: column-reverse;
                      display: flex;
                      align-items: center;
                      margin-top: auto;
                      height: 100%;
                      max-width: var(--vertical-bar-width);
                      transition: 3s;
                      overflow: hidden;
                      .score {
                        order: 2;
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 0;
                        padding-right: 0;
                        span {
                          display: block;
                          text-align: center;
                          word-wrap: break-word;
                        }
                      }
                    }
                  }
                  .scale {
                    display: flex;
                    flex-direction: column-reverse;
                    div {
                      border-top: var(--scale-border);
                      border-right: none;
                      span {
                        right: 100%;
                        top: -8px;
                        bottom: inherit;
                        transform: none;
                        @include generate-spacing(padding, 35, 'e');
                      }
                      &:first-child {
                        span {
                          min-width: auto;
                          left: auto;
                        }
                      }
                      &:last-child {
                        display: block;
                      }
                    }
                  }
                }
                .bar-label-group {
                  display: flex;
                  gap: var(--vertical-bar-gap);
                  width: 100%;
                  justify-content: center;
                  text-align: center;
                  @include generate-spacing(padding, 40, 's');
                  .bar-label {
                    width: 100%;
                    max-width: var(--vertical-bar-width);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
