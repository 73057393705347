@import "@glidejs/glide/src/assets/sass/glide.core";
@import "@glidejs/glide/src/assets/sass/glide.theme";

.amd-bs5 {
    .carousel {
        /**
        * Trick to get Full Width Containers in Limited Width Parents.
        * The idea here is: push the container to the exact middle of the browser window with left: 50%;,
        * then pull it back to the left edge with negative -50vw margin.
        */
        @media (max-width: 578px) {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
	        div[data-cmp-per-view="2"],
	        div[data-cmp-per-view="3"],
	        div[data-cmp-per-view="4"] {
				div[data-glide-el="controls"] {
					display: none;
				}
			}
        }
        
        .glide {
            opacity: 0;
            transition: opacity .5s;

            .glide__track {
                .glide__slides {
                    align-items: center;
                }
            }

            .glide__slide {
                .teaser {
                    @include generate-spacing(padding, 25, "e"); //fixes the dropshadow getting clipped

                    &.teaser-product,
                    &.teaser-profile {
                        @include generate-spacing(padding, 0, "e");
                    }
                }

                img {
                    user-drag: none;
                    -webkit-user-drag: none;
                    user-select: none;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                }
            }
        }
        .glide.paused {
            .glide__bullet--active:before {
                width: 100%;
                transition: none;
            }
        }
        &.carousel-align-bottom {
            .glide {
                .glide__track {
                    .glide__slides {
                        align-items: end;
                    }
                }
            }
        }

        &.carousel-align-stretch {
            .glide {
                .glide__track {
                    .glide__slides {
                        @include glide-slides-common;
                        .glide__slide {
                            @include glide-slide-common;
                            @include carousel-container-teaser;
                            @include carousel-teaser;
                            .container-fluid,
                            .container-lg {
                                @include carousel-teaser;
                            }
                        }
                    }
                }
            }
        }

        &.carousel-align-top {
            .glide {
                .glide__track {
                    .glide__slides {
                        align-items: start;
                    }
                }
            }
        }

        .glide-bullets {
            text-align: center;
            @include generate-spacing(padding, 45, 'b');

            button {
                width: 10px;
                height: 10px;
                border-radius: 100%;
                border: 0;
                background-color: $gray-500;
                padding: 0;
                @include generate-spacing(margin, 25, 's');
                @include generate-spacing(margin, 25, 'e');

                &.glide__bullet--active {
                    background-color: $gray-600;
                }
            }

            button.pause-button {
                vertical-align: top;
                background-color: transparent;
                color: $neutral-40;
                width: auto;
                height: auto;
                vertical-align: middle;
                padding-bottom: 2px;

                &:hover {
                    color: $neutral-80;
                }
            }
        }

        .glide__arrow {
            --glide-arrows-top: 45%;
            display: block;
            top: var(--glide-arrows-top);
            position: absolute;
            background-color: rgba(34, 34, 34, .3);
            border: none;
            border-radius: 2px;
            padding: map-get($spacers, 45) map-get($spacers, 35);
            font-size: 2rem;

            &--next {
                right: -4rem;
            }

            &--prev {
                left: -4rem;
            }

            &:hover {
                background-color: rgba(102, 102, 102, .3);
            }

            &.glide__arrow--next {
                right: 0;
            }

            &.glide__arrow--prev {
                left: 0;
            }
        }

        &.carousel-btn-outside {
            .glide__arrow {
                @include carousel-arrows-outside-position();

                @include media-breakpoint-down(lg) {
                    @include carousel-arrows-inside-position();
                }
            }
        }

        .glide--swipeable {
            cursor: auto;

            .glide__slide {
                cursor: grab;
            }
        }

        .carousel-indicator {
            color: $neutral-40;
            font-size: calc($font-size-lg * 4);

            &.glide__bullet--active {
                color: $neutral-80;
            }
        }
    }
}