.amd-bs5 {
	.image {
		@include generate-spacing(padding, 45, 'b');
		&.cmp-image__no-bottom-padding{
			@include generate-spacing(padding, 0, 'b');
		}
        
        @include media-breakpoint-down(md) {
            .clkImageZoomBtn {
                display: none;
            }
        }
        
		.clkImageZoomBtn {
            position: absolute;
            bottom: map-get($spacers, 40);
            left: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
            z-index: 1;
            pointer-events: none;
            span {
                background: rgba(255,255,255,.7);
                border-radius: 5px;
                @include generate-spacing(padding, 30, 'y');
                @include generate-spacing(padding, 40, 'x');
                color: $corporate-black;
                @include open-sans;
                font-size: $font-size-sm;
                font-weight: 500;
                i {
                   @include generate-spacing(margin, 30, 'e');
                }
            }
        }
		.cmp-image {
		    position: relative;
        	display: flex;
        	justify-content: center;
        	flex-flow: column;
            @include youtube-icons;
        	a {
        	    &:hover {
                    img.cmp-image__image {
                        @include opacity(.9);
                        @include box-transition(all, .3s, ease-in-out);
                    }
                }
        	    &.cmp-image__link {
                    &.clkImageEnlarge {
                        cursor: zoom-in;
                    }
                }
            }
		}
        .cmp-image__title {
            display: block;
            text-align: center;
            @include medium();
            @include generate-spacing(padding, 35, 'y');
            color: $neutral-50;
        }

        //modal styles
        &.title-left {
            .modal {
                .modal-dialog {
                    .cmp-image__title {
                        @include modal-title;
                        text-align:left;
                        display: block;
                    }
                }
            }
        }
        &.title-center {
            .modal {
                .modal-dialog {
                    .cmp-image__title {
                        @include modal-title;
                        text-align:center;
                        display: block;
                    }
                }
            }
        }
        &.title-right {
            .modal {
                .modal-dialog {
                    .cmp-image__title {
                        @include modal-title;
                        text-align: right;
                        display: block;
                    }
                }
            }
        }

        .modal {
            @include modal;
        }

        //icon styles
        &.icon-sm .cmp-image img {
            width: map-get($icon-size, "sm");
        }
        &.icon-md .cmp-image img {
            width: map-get($icon-size, "md");
        }
        &.icon-lg .cmp-image img {
            width: map-get($icon-size, "lg");
        }
      .yt-custom-img, .yt-thumbnail {
        border-radius: 20px;
        box-shadow: $floating;
      }
    }
}

